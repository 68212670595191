import { useState, useEffect } from 'react';
import { Box, Typography, FormControl, TextField, Stack, Select, MenuItem, Button } from '@mui/material';
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';
import { DatePicker} from '@mui/x-date-pickers';
import { format, parse } from 'date-fns';
import {SiDatadog} from 'react-icons/si';
import CustomButton from './CustomButton';
import { FormProps } from 'interfaces/common';
import { colores,  medioAnimal, razasGato, razasPerro } from 'constants/index';
import { ayuntamiento, moreliabrilla } from 'assets';
import { FaCat, FaDog } from 'react-icons/fa';
import loadingAnimation from "../../assets/loading.json";
import Lottie from 'lottie-react';

interface User {
  name: string;
  email: string;
  lastname: string;
  curp: string;
  sexo: string;
  _id: string;
  birthdate: string;
  zipcode: string;
  allAnimales: []; 
}

const Form = ({type, register, setValue, formLoading, handleSubmit, handleImageChange, onFinishHandler, animalImage} : FormProps) => {
  
  // options manager
  const [perro, setPerro] = useState(true)
  const [raza, setRaza] = useState(false)
  
  // date handling
  const dateFormat = 'dd/MM/yyyy';
  const today = format( new Date(), dateFormat );
  const maxDate = new Date()
  const referenceDate = new Date( 1997, 0, 1, 0, 0, 0 );
  
  const [selectedDate, setSelectedDate] = useState(today)
  const datefnsDate = parse( selectedDate, dateFormat, referenceDate );
  
  const handleDate = (newValue: Date) => {
    let formatedDateString = format( newValue, dateFormat );
    setSelectedDate(formatedDateString)
  } 

  useEffect(() => {
    setValue('birthdate', selectedDate)
  }, [setValue, selectedDate]);

  //  user 
  const [user, setUser] =  useState<User | null>(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedData = JSON.parse(storedUser);
      const email = parsedData.email;
      setValue('email', email);
      setUser(parsedData)
    }
  }, [setValue]);

  return (
  <div className='bg-forms lg:bg-contain bg-cover bg-no-repeat'>
    <Box className='md:max-w-screen-sm max-w-xs p-7 rounded-2xl shadow-2xl ml-auto mr-auto'>
      <Box display='flex' justifyContent='space-between' alignItems={{md:'center',xs:'start'}} className='mt-5'>
        <Box><img src={ayuntamiento} alt='logo' className='md:w-20 w-10'/></Box>
          <Typography fontSize={{lg:25, xs:18}} fontWeight={500} color="#11142d" className='flex flex-col items-center gap-2'> <SiDatadog className='text-moradoDark md:text-6xl text-5xl'/> Registro Animal </Typography>
        <Box><img src={moreliabrilla} alt='logo' className='md:w-24 w-12'/></Box>
      </Box>
      {formLoading ? 
        <div className="flex items-center justify-center w-full">
            <Lottie animationData={loadingAnimation}/>
        </div>  
      :
      <Box mt={2.5} borderRadius="15px" paddingBottom="10px" paddingX="20px" bgcolor="#fcfcfc">
        <form 
          style={{ width: '100%', display: 'flex', flexDirection: 'column', gap:'20px'}}
          onSubmit={handleSubmit(onFinishHandler)}
        >
          <FormControl>
            <TextField  sx={{display: 'none'}} defaultValue="compañia" {...register('procedencia')}/>
            <TextField  sx={{display: 'none'}} value={user?.email} {...register('email')}/>
          </FormControl>
{/* especie --------------------------------------------- */}
          <Stack direction="row" gap={4} flexWrap='wrap' alignItems='end'>
            <Stack sx={{flex:1, minWidth:'200px'}}>
              <Box>
                { perro? <FaDog className='text-5xl text-morado'/> : <FaCat className='text-5xl text-morado'/>}
              </Box>
              <FormControl sx={{flex:1}}>
                <Select
                  variant= "outlined"
                  color="info"
                  displayEmpty
                  required
                  defaultValue=""
                  className='textColor'
                  inputProps={{ "aria-label" : "Whithouth label"}}
                  {...register('especie', {required: true})}
                  >
                  <MenuItem  value="" disabled>Especie *</MenuItem>
                  <MenuItem value="perro" onClick={() => setPerro(true)}> Perro</MenuItem>
                  <MenuItem value="gato" onClick={() => setPerro(false)}>Gato</MenuItem>
                </Select>
              </FormControl>
            </Stack>
{/* medio --------------------------------------------- */}
            <FormControl sx={{flex:1, minWidth:'200px'}}>
                <Select
                  variant= "outlined"
                  className='textColor'
                  color="info"
                  displayEmpty
                  required
                  defaultValue=""
                  inputProps={{ "aria-label" : "Whithouth label"}}
                  {...register('medio', {required: false})}
                >
                  <MenuItem  value="" disabled>Procedencia *</MenuItem>
                  {medioAnimal.map((item,index) => <MenuItem key={index} value={item.value}>{item.text}</MenuItem>)}
                </Select>
              </FormControl>
          </Stack>
{/* ------------------------------------------------------- */}
{/* nombre --------------------------------------------- */}
          <Stack direction="row" gap={4} flexWrap='wrap' paddingTop={{lg:0, md:0, xs:2, sm:2}}>
            <FormControl sx={{flex:1, minWidth:'100px'}}>
              <TextField 
                fullWidth
                required
                placeholder='Escribe aquí'
                label='Nombre'
                {...register('nombre', {required: true})}
              />
            </FormControl>
{/* peso --------------------------------------------- */}
            <FormControl sx={{flex:1, minWidth:'100px'}}>
              <TextField 
                placeholder='Peso'
                fullWidth
                required
                label='Peso(kg)'
                type="number"
                {...register('peso', {required: false})}
              />
            </FormControl>
          </Stack>
{/* ------------------------------------------------------- */}
{/* sexo --------------------------------------------- */}
          <Stack direction="row" gap={4} flexWrap='wrap' paddingTop={{lg:0, md:0, xs:2, sm:2}}>
            <FormControl sx={{flex:1, minWidth:'100px'}}>
              <Select
                variant= "outlined"
                className='textColor'
                color="info"
                displayEmpty
                required
                defaultValue=""
                inputProps={{ "aria-label" : "Whithouth label"}}
                {...register('sexo', {required: true})}
              >
                <MenuItem  value="" disabled>Sexo *</MenuItem>
                <MenuItem value="masculino">Macho</MenuItem>
                <MenuItem value="femenino">Hembra</MenuItem>
              </Select>
            </FormControl>
{/* birthdate --------------------------------------------- */}
            <FormControl sx={{flex:1, minWidth:'100px'}}>
              <DatePicker 
                label="Fecha de Nacimiento *"
                value={datefnsDate}
                minDate={referenceDate}
                maxDate={maxDate}
                onChange={(newValue) => handleDate(newValue as Date)} 
              />
              <TextField
                sx={{display: 'none'}} 
                required
                value={selectedDate}
                {...register('birthdate', {required: true})}    
              />
            </FormControl>
          </Stack>
{/* ------------------------------------------------------- */}
{/* raza --------------------------------------------- */}
          <Stack direction="row" gap={4} flexWrap='wrap' paddingTop={{lg:0, md:0, xs:2, sm:2}}>
              <FormControl sx={{flex:1, minWidth:'100px'}}>
                <Select
                  variant= "outlined"
                  color="info"
                  displayEmpty
                  required
                  className='textColor'
                  defaultValue=""
                  inputProps={{ "aria-label" : "Whithouth label"}}
                  {...register('raza', {required: true})}
                >
                  <MenuItem  value="" disabled>Raza *</MenuItem>
                  <MenuItem value="criolla" onClick={() => setRaza(false)}>Criolla</MenuItem>
                  <MenuItem value="raza" onClick={() => setRaza(true)}>Raza</MenuItem>
                </Select>
              </FormControl>
{/* razaUnica --------------------------------------------- */}
              { raza ?
                <FormControl sx={{flex:1, minWidth:'100px'}}>
                  <Select
                    variant= "outlined"
                    color="info"
                    displayEmpty
                    className='textColor'
                    required
                    defaultValue=""
                    inputProps={{ "aria-label" : "Whithouth label"}}
                    {...register('razaUnica', {required: false})}
                  >
                    <MenuItem  value="" disabled>* Elegir Raza</MenuItem>
                    { perro ?
                      razasPerro.map((raza,index) => <MenuItem value={raza.value} key={index}>{raza.text}</MenuItem>) 
                      :
                      razasGato.map((raza,index) => <MenuItem value={raza.value} key={index}>{raza.text}</MenuItem>) 
                    }
                  </Select>
                </FormControl> 
                :
                <></>
              }
          </Stack>
{/* ------------------------------------------------------- */}
{/* color --------------------------------------------- */}
          <Stack direction="row" gap={4} flexWrap='wrap' paddingTop={{lg:0, md:0, xs:2, sm:2}}>
            <FormControl sx={{flex:1, minWidth:'100px'}}>
              <Select
                variant= "outlined"
                color="info"
                displayEmpty
                required
                defaultValue=""
                className='textColor'
                inputProps={{ "aria-label" : "Whithouth label"}}
                {...register('color', {required: true})}
              >
                <MenuItem  value="" disabled>Color principal *</MenuItem>
                {colores.map((color,index) => <MenuItem key={index} value={color.value}>{color.text}</MenuItem>)}
              </Select>
            </FormControl>
{/* esterilizado --------------------------------------------- */}
            <FormControl sx={{flex:1, minWidth:'100px'}}>
                <Select
                  variant= "outlined"
                  color="info"
                  displayEmpty
                  required
                  className='textColor'
                  defaultValue=""
                  inputProps={{ "aria-label" : "Whithouth label"}}
                  {...register('esterilizado', {required: true})}
                >
                  <MenuItem  value="" disabled>Esterilizado *</MenuItem>
                  <MenuItem value="si">Si</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              </FormControl>
          </Stack>
{/* ------------------------------------------------------- */}
{/* photo --------------------------------------------- */}
          <Stack direction="column" gap={1} justifyContent="center" mb="2" >

            <Stack direction="row" gap={2}>
              <Button component="label" sx={{width: 'fit-content', color: '#fff', textTransform:'capitalize', fontSize: 16, fontWeight: 500, background:'#8e6fbd', justifyContent: "center", padding: "16px", borderRadius: "12px", minHeight: "56px", "&:hover": { background: 'purple'}}}>

                <AddAPhotoRoundedIcon sx={{marginRight:'10px'}}/>
                { animalImage.name !== "" ?
                  <div>
                    <img src={animalImage.url} alt="animal-preview" className='w-44 rounded-lg'/> 
                  </div>
                  :
                  <h1>Añadir Foto *</h1>
                }
                <input 
                  hidden
                  accept='image/*'
                  type="file"
                  onChange={(e) => {
                    // @ts-ignore
                    handleImageChange(e.target.files[0])
                  }}
                />
              </Button>
            </Stack>
          </Stack>
{/* cuando fue creado --------------------------------------------- */} 
          <TextField
                sx={{display: 'none'}} 
                value={today}
                {...register('createdAt')}    
          />
          <CustomButton 
            type="submit"
            title={'Registrar'}
            backgroundColor="purple"
            color="#fcfcfc"
          />
        </form>
      </Box>
    }
    </Box>
  </div>
  )
}

export default Form