import { animales } from "assets"
import NumberCard from "./NumberCard"
import { useEffect, useState } from "react";
import axios from "axios";

import { Variants, motion } from "framer-motion"
import { slideTop } from "utils/motion";

const Up: Variants = slideTop('up', 'spring', 0, 1)

interface Counter {
  count: number | null;
}

export const Stats = () => {
  const [animals, setAnimals] = useState<Counter | null>(null);
  const [users, setUsers] = useState<Counter | null>(null);
  
  useEffect(() => {
      const fetchData = async () => {
        try {
            const response = await axios.get('https://juskas.onrender.com/api/v1/animales/');
            setAnimals(response.data);
        } catch (error) {
            console.error('Error fetching animals:', error);
        }
      };
      const fetchUsers = async () => {
        try {
          const response = await axios.get('https://juskas.onrender.com/api/v1/usuarios/');
          setUsers(response.data);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      };
      fetchData();
      fetchUsers();
  }, []);
      
    return (
      <motion.div 
        className="flex flex-col items-center gap-4 w-full"
        variants={Up}
        initial="hidden"
        whileInView="show"
      >
        <img src={animales} alt="animales" />
        <div className="flex justify-evenly w-full flex-wrap gap-5">
          <div className="flex flex-col items-center">
            <NumberCard stat={animals?.count !== undefined ? animals?.count : 0}/>
            <h2>Animales Registrados</h2>
          </div>
          <div className="flex flex-col items-center">
            <NumberCard stat={users?.count !== undefined ? users?.count : 0}/>
            <h2>Usuarios Registrados</h2>
          </div>
        </div>
      </motion.div>
    )
  }