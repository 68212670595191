import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import './index.css'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {es} from 'date-fns/locale';

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <React.Suspense fallback="loading">
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <App />
      </LocalizationProvider>
    </React.Suspense>
  </React.StrictMode>
);
