import React, { useEffect, useState } from 'react';
import { morelialg } from "assets";
import { layoutStyles, titleStyles } from "./styles";
import { Button, TextField, Box, Typography, Container, CardContent, Link as MuiLink, InputAdornment, IconButton } from "@mui/material";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { authProvider } from '../../authProvider';
import loadingAnimation from "../../assets/loading.json";
import Lottie from 'lottie-react';
import { Helmet } from 'react-helmet';
import { RiLockPasswordLine } from 'react-icons/ri';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface FormData {
    name: string;
    email: string;
    lastname: string;
    password: string;
    sexo: string;
    birthdate: string;
    curp: string;
    zipcode: string;
    role: string;
}

interface BackendError {
    email: {
        message: string ;
    }
}

interface CurpErrors {
    curp: {
        message: string ;
    }
}

export const Register: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorCurp, setErrorCurp] = useState(false);
    const [backendErrors, setBackendErrors] = useState<BackendError | null>(null);
    const [curpErrors, setCurpErrors] = useState<CurpErrors | null>(null);
    const [showPassword, setShowPassword] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
    } = useForm<FormData>();
    
    const onSubmit = async (formData: FormData) => {
      try {
        setLoading(true)        
        const response = await axios.post(
          "https://juskas.onrender.com/api/v1/usuarios/register",
          formData
        );
        const responseData = response.data;
        // Accede a los datos de respuesta del backend
        if (responseData.success) {
          console.log(responseData.message);
          console.log(responseData.user);
    
          const loginResponse = await authProvider.login({
            email: formData.email,
            password: formData.password,
          });
    
          if (loginResponse.success) {
            // console.log("Inicio de sesión exitoso");
            window.location.href = "/";
          } else {
            // El inicio de sesión falló
            console.log("Error en el inicio de sesión");
          }
        } else {
          // El backend devolvió un error
          console.log(responseData.message);
        }
        } catch (error: any) {
        setLoading(false)
        if (error.response && error.response.status === 409) {
          if (error.response.data.message === "email") {
            setError(true)
            setBackendErrors({
              email: {
                message: "El correo ya está registrado",
              },
            });
          } else if (error.response.data.message === "curp") {
            setErrorCurp(true)
            setCurpErrors({
              curp: {
                message: "La CURP ya está registrada",
              },
            });
          }
        }
      }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
          setError(false);
          setErrorCurp(false);
          setBackendErrors(null);
          setCurpErrors(null)
        }, 5000); // establece el tiempo de espera deseado en milisegundos
      
        return () => clearTimeout(timer); // limpia el temporizador si se vuelve a escribir en el input antes de que se cumpla el tiempo de espera
      }, [curpErrors, backendErrors]);

    
// ------------------------------- Verificar CP
const moreliacps = [
    // Morelia
    58000, 58002, 58003, 58004, 58006, 58007, 58009, 58010, 58012, 58013, 58020, 58030, 58040, 58048, 58049, 58050, 58055, 58056, 58057, 58058, 58060, 58066, 58067, 58069, 58070, 58078, 58079, 58080, 58085, 58086, 58087, 58088, 58089, 58090, 58093, 58095, 58096, 58097, 58098, 58099, 58100, 58110, 58114, 58115, 58116, 58117, 58118, 58120, 58127, 58128, 58130, 58140, 58145, 58146, 58147, 58148, 58149, 58150, 58158, 58160, 58168, 58170, 58175, 58178, 58179, 58180, 58183, 58186, 58187, 58188, 58189, 58190, 58191, 58192, 58194, 58195, 58196, 58197, 58199, 58200, 58210, 58216, 58217, 58218, 58219, 58220, 58221, 58222, 58223, 58224, 58225, 58226, 58227, 58228, 58229, 58230, 58231, 58232, 58233, 58234, 58235, 58236, 58237, 58238, 58239, 58240, 58241, 58242, 58243, 58244, 58245, 58246, 58247, 58248, 58249, 58250, 58251, 58252, 58253, 58254, 58255, 58256, 58257, 58258, 58259, 58260, 58261, 58262, 58263, 58264, 58265, 58266, 58267, 58268, 58269, 58270, 58271, 58272, 58273, 58274, 58275, 58276, 58277, 58278, 58279, 58280, 58281, 58282, 58283, 58284, 58285, 58286, 58287, 58288, 58289, 58290, 58291, 58292, 58293, 58294, 58295, 58296, 58297, 58298, 58299, 58300, 58301, 58302, 58303, 58304, 58305, 58306, 58307, 58308, 58309, 58310, 58311, 58312, 58313, 58314, 58315, 58316, 58317, 58318, 58319, 58320, 58321, 58322, 58323, 58324, 58325, 58326, 58327, 58328, 58329, 58330, 58331, 58332, 58333, 58334, 58335, 58336, 58337, 58338, 58339, 58340, 58341, 58342, 58343, 58344, 58345, 58346, 58347, 58348, 58349, 58350, 58351, 58352, 58353, 58354, 58355, 58356, 58357, 58358, 58359, 58420,
    // Charo
    61327, 61323, 61321, 61328, 61322, 61312, 61300, 61314, 61304, 61320, 61324, 61305, 61306, 61303,
    // Álvaro Obregón
    58840, 58844, 58850, 58854, 58855, 58857, 58858, 58860, 58863, 58864, 58865, 58866, 58867, 58868, 58869, 58870, 58873, 58874, 58875, 58876, 58877, 58878, 58879, 58880, 58883, 58884, 58885, 58886, 58887, 58888, 58889, 58900, 58902, 58903, 58904, 58905, 58906, 58907, 58908, 58909, 58910, 58913, 58914, 58915, 58916, 58917, 58918, 58919, 58920, 58923, 58924, 58925, 58926, 58927, 58928,
    // Tarimbaro
    58887, 58887, 58880, 58891, 58880, 58893, 58885, 58886, 58880, 58893, 58890, 58896, 58880, 58893, 58890, 58881, 58880, 58880, 58890, 58880, 58880, 58893, 58893, 58885, 58895, 58895, 58891, 58885, 58893, 58893, 58881, 58893, 58893, 58881, 58880, 58880, 58880, 58880, 58880, 58881, 58880, 58880, 58880, 58881, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58881, 58880, 58880, 58880, 58880, 58881, 58881, 58880, 58880, 58881, 58880, 58881, 58880, 58880, 58880, 58880, 58880, 58880, 58881, 58880, 58880, 58881, 58881, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58881, 58880, 58880, 58881, 58881, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58881, 58881, 58880, 58880, 58881, 58881, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58881, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58881, 58881, 58880, 58880, 58881, 58880, 58881, 58880, 58880, 58880, 58880, 58881, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58881, 58881, 58880, 58880, 58881, 58881, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880, 58880,
  ]

    const validateZipcode = (value: string | undefined) => {
        if (!value || !moreliacps.includes(parseInt(value))) {
          return "Código Postal no es dentro de Morelia";
        }
        return undefined;
    };

// ------------------------------- Extraer valores de la CURP
    const curpValue = watch("curp");

    React.useEffect(() => {
        if (curpValue) {
            const anio = curpValue.slice(4, 6);
            const mes = curpValue.slice(6, 8);
            const dia = curpValue.slice(8, 10);
            const fecha = `${dia}/${mes}/${anio}`;
            const letra = curpValue.slice(10,11);
            const sexo = letra === 'H' ? 'Hombre' : letra === 'M' ? 'Mujer' : '';
            setValue("birthdate", fecha); // <--- Actualizar el valor utilizando setValue
            setValue("sexo", sexo); // <--- Actualizar el valor utilizando setValue
            const birthdateElement = document.querySelector('input[name="birthdate"]') as HTMLInputElement;
            const sexoeElement = document.querySelector('input[name="sexo"]') as HTMLInputElement;
            if (birthdateElement) {
                birthdateElement.value = fecha;
                sexoeElement.value= sexo;
            }
        } else {
            setValue("birthdate", ""); // <--- Establecer el valor como vacío utilizando setValue
            setValue("sexo", ""); // <--- Establecer el valor como vacío utilizando setValue
        }
    }, [curpValue, setValue]);

    return (
        <div className="bg-forms bg-contain bg-no-repeat">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Regístrate</title>
                <meta property="og:title" content="Regístrate" />
                <meta property="og:description" content="Crea una cuenta en el Registro Público Municipal de Animales de Compañia, administra tus animales, activa SOS Juskas y conecta tu microchip"/>
            </Helmet>
            <Container maxWidth="sm">
                <Box component="div" style={layoutStyles} className='flex items-center w-full justify-center min-h-screen my-10'>
                <Container
                    component="main"
                    className='shadow-xl rounded-xl'
                    maxWidth="xs"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <CardContent sx={{ p: "32px", "&:last-child": { pb: "32px" } }} component="form" onSubmit={handleSubmit(onSubmit)} >
                        <a href="/home" className="flex justify-center mb-5"><img src={morelialg} alt="morelia-imagotipo"  height='10px' width="200px" style={{paddingTop: "20px"}}/></a>
                        <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                            style={titleStyles}
                            color="primary"
                            fontWeight={700}
                        >
                        Regístrate
                        </Typography>
                        {loading ? 
                        <div className="w-full flex items-center justify-center">
                            <Lottie animationData={loadingAnimation}/>
                        </div>  
                        :
                        <div>                            
        {/* Nombre ----------------------------------*/}
                            <TextField
                                id="name"
                                margin="normal"
                                fullWidth
                                label="Nombre(s)"
                                autoComplete="name"
                                inputProps={{ maxLength: 25 }}
                                sx={{
                                    mt: 0,
                                    mb: 3,
                                }}
                                {...register("name", { required: true, })}
                            />
        {/* Apellido ----------------------------------*/}                                        
                            <TextField
                                {...register("lastname", {
                                    required: true,
                                })}
                                id="lastname"
                                margin="normal"
                                fullWidth
                                label="Apellido(s)"
                                name="lastname"
                                autoComplete="lastname"
                                inputProps={{ maxLength: 25 }}
                                sx={{
                                    mt: 0,
                                    mb: 3,
                                }}
                            />
        {/* CURP ----------------------------------*/} 
                            <TextField
                                {...register("curp", {
                                    required: true,
                                    pattern: {
                                        value: /^([A-ZÑ][AEIOUXÁÉÍÓÚ][A-ZÑ]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/i,
                                        message: "CURP inválido",
                                    },
                                })}
                                id="curp"
                                margin="normal"
                                fullWidth
                                inputProps={{ maxLength: 30 }}
                                label="CURP"
                                error={!!errors.curp || errorCurp}
                                helperText={
                                    errors["curp"] 
                                    ? errors["curp"].message
                                    : curpErrors?.curp?.message || ""
                                }
                                name="curp"
                                sx={{
                                    mt: 0,
                                    mb: 3,
                                }}
                            />
        {/* Fecha de Nacimiento ----------------------------------*/}
                                <TextField 
                                    {...register("birthdate", {
                                        required: true,
                                    })}
                                    value={watch("birthdate") || ""}
                                    fullWidth
                                    disabled
                                    placeholder="DD/MM/AA"
                                    margin="normal"
                                    label='Fecha de Nacimiento'
                                    name="birthdate"
                                    sx={{
                                        mt: 0,
                                        mb: 3,
                                    }}
                                />
        {/* Sexo ----------------------------------*/}
                                <TextField 
                                    {...register("sexo", {
                                        required: true,
                                    })}
                                    value={watch("sexo") || ""}
                                    fullWidth
                                    disabled
                                    margin="normal"
                                    label='Sexo'
                                    name="sexo"
                                    sx={{
                                        mt: 0,
                                        mb: 3,
                                    }}
                                />
        {/* Zip Code ----------------------------------*/} 
                            <TextField
                                {...register("zipcode", {
                                    required: true,
                                    validate: validateZipcode,
                                })}
                                id="zipcode"
                                margin="normal"
                                inputProps={{ maxLength: 5 }}
                                fullWidth
                                label="Domicilio"
                                placeholder="Código Postal"
                                error={!!errors.zipcode}
                                helperText={
                                    errors["zipcode"] ? errors["zipcode"].message : ""
                                }
                                name="zipcode"
                                sx={{
                                    mt: 0,
                                    mb: 3,
                                }}
                            />
        {/* Email ----------------------------------*/} 
                            <TextField
                                {...register("email", {
                                    required: true,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Email inválido",
                                    },
                                })}
                                id="email"
                                margin="normal"
                                fullWidth
                                label="Correo Electrónico"
                                error={!!errors.email || error}
                                inputProps={{ maxLength: 40 }}
                                helperText={
                                    errors["email"] 
                                    ? errors["email"].message 
                                    : backendErrors?.email?.message || ""
                                }
                                name="email"
                                sx={{
                                    mt: 0,
                                }}
                            />
        {/* Contraseña ----------------------------------*/} 
                            <TextField
                                {...register("password", {
                                    required: true,
                                })}
                                id="password"
                                margin="normal"
                                fullWidth
                                name="password"
                                label="Contraseña"
                                helperText={
                                    errors["password"] ? errors["password"].message : ""
                                }
                                error={!!errors.password}
                                type={showPassword ? 'text' : 'password'}
                                placeholder="●●●●●●●●"
                                InputProps={{
                                    startAdornment: <RiLockPasswordLine className="mx-2 text-lg"/>,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    mb: 0,
                                }}
                            />
        {/* Botón Enviar */}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: "24px",
                                }}
                            >
                                Registrar
                            </Button>
                                <h1 className='text-[10px] text-center mt-5'>
                                    Al dar click en registrar aceptas el Aviso de Privacidad vigente y los Términos del programa
                                </h1>
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="center"
                                sx={{
                                    mt: "24px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    component="span"
                                    fontSize="12px"
                                >
                                    ¿Tienes una cuenta?
                                </Typography>
                                <MuiLink
                                    component={RouterLink}
                                    ml="4px"
                                    variant="body2"
                                    color="primary"
                                    underline="none"
                                    to="/login"
                                    fontSize="12px"
                                    fontWeight="bold"
                                >
                                    Iniciar Sesión
                                </MuiLink>
                            </Box>
                        </div>
                        }
                    </CardContent>
                </Container>
                </Box>
            </Container>
        </div>
    );
};