import { adopta, animales, moreliabrilla } from 'assets';
// import { GiLoveMystery } from 'react-icons/gi';
// import { MdPets } from 'react-icons/md';

// const dummy = {
//     perfilAdopcion:false,
// }


const Adopciones = () => {
  return (
    <div className='p-4'>
        {/* Titulo */}
        <div className='flex justify-between items-center'>
            <div className='flex gap-3 items-center'>
                <img src={adopta} alt='adopta' className='md:h-20 h-14'/>
                <div className='flex flex-col'>
                    <h2 className='font-light'>Mascotas en Adopción</h2>
                    <h1 className='font-bold text-2xl'>Descubre</h1>
                </div>
            </div>
            <img src={moreliabrilla} alt='logo morelia' className='h-16 md:h-24'/>
        </div>
        <div className='flex flex-col gap-4 mt-10 items-center justify-center'>
            <img src={animales} alt="animales" className='w-72'/>
            <h1 className='text-2xl text-center font-semibold'>
                Próximamente
            </h1>
        </div>
        {/* Validar perfil de adopción */}
        {/* { !dummy.perfilAdopcion ?
        // Panel sin perfil de adopción
            <div className='mt-10 text-lg font-light flex flex-col gap-2 justify-center items-center'>
                <img src={animales} alt="animales" className='w-72'/>
                <h1>Para comenzar y ver las mascotas necesitas crear un perfil de adopción</h1>
                <button className='w-96 bg-gradient-to-r from-[#3b82f6] to-[#9c27b0] rounded-2xl text-white py-10 my-2 text-center flex flex-col items-center justify-center gap-2 hover:scale-95 duration-300'>
                    <GiLoveMystery className='text-5xl'/>
                    <h1 className='text-2xl text-white font-bold'>Crear Perfil</h1>
                </button>
            </div>
            :
        // Panel cuando ya tienen perfil de adopción
            <div>
                <div className='flex gap-4 text-left font-medium my-3'>
                    <button className='w-96 bg-gradient-to-r from-[#3b82f6] to-[#9c27b0] rounded-2xl text-white p-3 my-2 flex items-center justify-center text-left gap-2 hover:scale-95 duration-300'>
                        <GiLoveMystery className='text-5xl'/>
                        <h1 className='flex-1'>Perfil de adopción</h1>
                    </button>
                    <button className='w-96 bg-gradient-to-r from-[#3b82f6] to-[#9c27b0] rounded-2xl text-white p-3 my-2 flex items-center text-left justify-center gap-2 hover:scale-95 duration-300'>
                        <MdPets className='text-5xl'/>
                        <h1>Mis solicitudes</h1>
                    </button>
                </div>
                <p className='font-light '>Encuentra a tu compañero de vida ideal</p>
                <div className=''>

                </div>
            </div>
        } */}
    </div>
  )
}

export default Adopciones