import Lottie from 'lottie-react';
import errorAnimation from '../../assets/loading.json'
import { juskas, moreliabrilla } from "assets";
import { FaRegUser } from 'react-icons/fa';
import { IoMdLogIn, IoMdQrScanner } from 'react-icons/io';
import { FiEdit3 } from 'react-icons/fi';
import { LuSmartphoneNfc } from 'react-icons/lu';

const ErrorID = () => {

  return ( 
    <div className='flex flex-col gap-1  min-h-screen px-6 max-w-md ml-auto mr-auto'>
        <div className="flex gap-5 items-center justify-evenly my-5">
            <img src={moreliabrilla} alt="ayuntamiento" className="w-24"/>
            <img src={juskas} alt="juskas" className="w-24"/>
        </div>
        <div className='mt-3 mb-5 flex flex-col gap-4'>
            <h2 className='font-bold text-3xl text-center'>Hola, bienvenid@</h2>
            <p className='font-light text-lg text-center'>Para empezar a usar y disfrutar tu microchip de Juskas</p>
            {/* Steps */}
            <div className='flex flex-col gap-5'>
                <div className='flex gap-2 items-start'>
                    <div className='bg-morado text-white p-3 rounded-2xl'>
                        <FaRegUser />
                    </div>
                    <div className='flex flex-col items-start gap-2'>
                        <p>Crea una cuenta o inicia sesión</p>
                        <a href='/login' className='text-sm bg-morado text-white px-3 py-3 rounded-[10px] duration-300 hover:bg-moradoDark flex items-center gap-2'>Iniciar aquí <IoMdLogIn /></a>
                    </div>
                </div>
                <div className='flex gap-2 items-center'>
                    <div className='bg-morado text-white p-3 rounded-2xl'>
                        <FiEdit3 />
                    </div>
                    <p>Registra a tus animales de compañía y entra en su perfil</p>
                </div>
                <div className='flex gap-2 items-center'>
                    <div className='bg-morado text-white p-3 rounded-2xl'>
                        <LuSmartphoneNfc />
                    </div>
                    <p>Da click en actualizar datos y vincular microchip</p>
                </div>
                <div className='flex gap-2 items-center'>
                    <div className='bg-morado text-white p-3 rounded-2xl'>
                        <IoMdQrScanner />
                    </div>
                    <p>Escanea el código QR ¡Y listo! Ya estará vinculado</p>
                </div>
            </div>
        </div>
        <div className='flex items-center flex-col mb-2'>
            <Lottie animationData={errorAnimation} className="max-w-sm"/>
            <h3 className='text-center font-light'><span className='font-bold'>Ellos brillan</span> y nos hacen brillar más</h3>
        </div>
    </div>
  )
}

export default ErrorID