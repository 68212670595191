import dogAnimation from "../../assets/dog.json";
import catAnimation from "../../assets/cat.json";
import success from "../../assets/success.json";
import Lottie from 'lottie-react';
import { Button } from "@mui/material";

const RegistroExitoso = () => {
  return (
    <div className="flex flex-col gap-10 items-center p-3 mt-10">
        <div className="flex items-end justify-center w-full">
            <div>
                <Lottie animationData={catAnimation} className="w-[6rem]"/>
            </div>
            <div>
                <Lottie animationData={dogAnimation} className="w-[6rem]"/>
            </div>
        </div>
        <h1 className="text-3xl font-semibold text-center">¡Registro de Animal de Compañía Exitoso!</h1>
        <Lottie animationData={success} className="w-[16rem]"/>
        <div className="max-w-sm">
            <h1 className="text-basis font-extralight text-center">Ve a la página de detalle de cada animal para activar los Microchips y modo SOS</h1>
            <a href="/animales">
                <Button   
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                        mt: "10px",
                    }}
                >
                        Ver Mis Animales
                </Button>
                <h1 className="mt-[10px] text-basis font-extralight text-center">¡Gracias por ser un Dueño responsable!</h1>
            </a>
        </div>
        <div>
            <h1 className="text-lg font-light text-center">Ya puedes descargar el QR Único de tus animales registrados</h1>
            <h1 className="text-base font-light text-center mt-1">Ingresa al perfil de cada animal y selecciona "Descargar QR</h1>
            <h1 className="text-lg font-light text-center mt-3">Próximamente entrega de microchips</h1>
        </div>
    </div>
  )
}

export default RegistroExitoso