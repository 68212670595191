import { Typography, Box, Stack, Button } from "@mui/material";
import { useShow } from "@refinedev/core"; 
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeft, HealthAndSafety, LocalHospital, Pets, Phone, Photo, Sos} from "@mui/icons-material";
import { BsGenderFemale, BsGenderMale, BsHouseHeart, BsHouses, BsHouseSlash} from 'react-icons/bs';
import QRCode from "react-qr-code";
import { CustomButton } from "components";
import { TbCat, TbDog } from "react-icons/tb";
import Map from "components/common/Map";
import { ayuntamiento, juskas, morelialg, whiteayuntamiento, whitejuskas, whitemoreliabrilla } from "assets";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useEffect, useRef, useState } from "react";
import { HiOutlineDownload } from "react-icons/hi";
import { FaEdit, FaHome } from "react-icons/fa";
import { RiSendPlaneFill } from "react-icons/ri";
import loadingAnimation from "../../assets/loading2.json";
import loadingAnimationDog from "../../assets/loading.json";
import loadingError from "../../assets/404.json";
import Lottie from 'lottie-react';
import axios from "axios";
import { useForm } from "react-hook-form";
import { IoMdQrScanner } from "react-icons/io";
import { QrReader } from "react-qr-reader";
import { notification } from "antd";

interface FormData {
  _id:string,
  photo:string,
}

const AnimalesDetalles = () => {
  const navigate  = useNavigate();
  const { id } = useParams();
  const { queryResult } = useShow();
  const { data, isLoading, isError } = queryResult;
  const animalDetalles = data?.data ?? {};
  const printRef = useRef<HTMLDivElement | null>(null);
  
  // UseForm para Enviar la imagen
  const { handleSubmit, register } = useForm<FormData>();
  const [imageLoading, setImageLoading] = useState(false);
  // Vincular
  const [vincular, setVincular] = useState(false);
  const [dataID, setDataID] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const activarVincular = () => {
      setVincular(true)
      setDataID(null)
      setError(false)
      setSuccess(false)
  }

  const desactivarVincular = () => {
      setVincular(false)
      setDataID(null)
      setError(false)
      setSuccess(false)
  }

  const quitarError = () => {
    window.location.reload();
  }

  // imagen 
  const [animalImage, setAnimalImage] = useState({ name: '', url: ''})

  const handleImageChange = (file: File) => {
    const reader = (readFile: File) => new Promise<string>
    ((resolve,reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => resolve(fileReader.result as string);
      fileReader.readAsDataURL(readFile);
    })
    reader(file).then((result:string) => setAnimalImage({name:file?.name, url:result }));
  }; 

  const onSubmit = async (formData: FormData) => {
    try {
      setImageLoading(true)
      formData.photo = animalImage.url;
      const response = await axios.post('https://juskas.onrender.com/api/v1/animales/image', formData);
      // Recarga la página después de que se hayan guardado los cambios
      window.location.reload();
  
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  // pdf
  const handleDownloadPdf = () => {
    if (printRef.current) {
      const element = printRef.current;
      html2canvas(element).then(canvas => {
        const data = canvas.toDataURL("image/png");

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("QR-Juskas.pdf");
      });
    }
  };

  useEffect(() => {
    const userString = localStorage.getItem('user');
    if(userString) {
    const user = JSON.parse(userString);
    setEmail(user.email)
    }
    const fetchData = async () => {
      if(dataID) {
        const tag = dataID?.split("/").pop()?.trim();
        setLoading(true)
        try {
          const response = await axios.post('https://juskas.onrender.com/api/v1/tag/vincular-tag', { tag, email, id });
          if (response.data.success) {
            setLoading(false)
            setVincular(false)
            setSuccess(true)
            notification.success({
                message: "Vinculación exitosa",
                description: `Tu microchip fue vinculado correctamente`,
                placement: 'bottomRight',
            });
          } 
        } catch (error) {
          setLoading(false)
          setVincular(false)
          setError(true)
          notification.error({
            message: "Error al registrar microchip",
            description: `Código no valido o ya usado`,
            placement: 'bottomRight',
          });
          return { success: false, error: { name: "Error", message: "Error en el servidor", },
        };
        
      }
      }
    }
    fetchData();
  },[dataID])
  
  if (isError) {
    return (
      <div>
        <div className="flex flex-col items-center justify-center w-full min-h-screen">
          <Lottie animationData={loadingError} className="max-w-sm"/>
          <h1 className="text-2xl font-semibold mb-5">La página que estás buscando no existe</h1>
          <CustomButton title="Regresar al inicio" handleClick={() => navigate('/')} backgroundColor="#9c27b0" color="#fcfcfc" icon={<FaHome />} />
        </div> 
      </div> 
    )
  }

  if (isLoading) {
    return   (
      <div>
        <div className="flex flex-col items-center justify-center w-full min-h-screen">
          <Lottie animationData={loadingAnimation} className="max-w-sm"/>
        </div>  
      </div>
    )
  }

  return (
    <Box>
      <Box gap={4} className='p-20px flex justify-center flex-wrap'>
{/* ------------------ User Menu Card ------------------ */}
      <Box className='flex flex-col gap-10 w-full max-w-sm'>
        <Box className='w-full h-fit flex flex-col p-4 justify-center items-center bg-white gap-3 rounded-2xl shadow-2xl'>
            <CustomButton title={'Regresar al Menú'} fullWidth backgroundColor={"#9c27b0"} color={"#fcfcfc"} icon={<ArrowLeft/>} handleClick={() => navigate('/animales/')}/>
            { (!error && !success) &&
              <div className={`${(vincular && !loading) ? 'hidden' : 'w-full'}`}>
                <CustomButton title={'Vincular microchip'} backgroundColor="#1a3b66" color="#fcfcfc" fullWidth icon={<IoMdQrScanner />} handleClick={() => activarVincular()} />
              </div>
            }
            <div className={`${(vincular && !loading) ? 'w-full' : 'hidden'}`}>
              <CustomButton title={'Desactivar vincular'} backgroundColor="#1a3b66" color="#fcfcfc" fullWidth icon={<IoMdQrScanner />} handleClick={() => desactivarVincular()} />
            </div>
            { loading &&
                  <div className='flex items-center flex-col mb-2'>
                    <Lottie animationData={loadingAnimationDog} className="max-w-sm"/>
                    <h3 className='text-lg text-center font-bold'>Vinculando...</h3>
                  </div>
            }
            { error &&
                <CustomButton title={'Error, Intentar de nuevo'} backgroundColor="red" color="#fcfcfc" fullWidth icon={<IoMdQrScanner />} handleClick={() => quitarError()} />
            }
            { success &&
                <CustomButton title={'Microchip vinculado'} backgroundColor="green" color="#fcfcfc" fullWidth icon={<IoMdQrScanner />} handleClick={() => quitarError()} />
            }
            {(vincular && !loading && !error && !success) &&
              <Box>
                  <Box className='md:max-w-[22rem] w-full ml-auto mr-auto rounded-[10px] bg-azul p-2 mt-5'>
                      <QrReader
                          onResult={(result, error) => {
                              if (!!result) {
                              setDataID(result?.getText());
                              }
                              if (!!error) {
                              }
                          }}
                          containerStyle={{ width: '100%' }}
                          constraints={{
                              width: { min: 720 },
                              height: { min: 720 },
                              aspectRatio: { exact: 1 },
                              frameRate: { ideal: 10, max: 15 },
                              facingMode: "environment"
                          }}
                      />
                  </Box>
                  <p className='mb-5 mt-2 font-light'>Escanea el código QR de tu microchip para vincular.</p>
              </Box>
            }
            <CustomButton title={'Actualizar Datos'} backgroundColor="#6e4ea1" color="#fcfcfc" fullWidth icon={<FaEdit />} handleClick={() => (navigate(`/animales/edit/${animalDetalles._id}`))} />

            {imageLoading ? 
              <Box className='flex flex-col w-full gap-2 items-center'>
                <Button component="label" sx={{ flex: 1, padding: "10px 15px", width: "100%", minWidth: 130, backgroundColor:"#6e4ea1", color:"#fcfcfc", fontSize: 16, fontWeight: 600, gap: "10px", textTransform: "capitalize", borderRadius:'10px', "&:hover": { opacity: 0.9, backgroundColor: "#6e4ea1" }}}>
                  Enviando Imagen...
                </Button>
              </Box>
              :
              <Box className='flex w-full gap-2' component="form" onSubmit={handleSubmit(onSubmit)}>
                <Button component="label" sx={{ flex: 1, padding: "10px 15px", width: "100%", minWidth: 130, backgroundColor:"#6e4ea1", color:"#fcfcfc", fontSize: 16, fontWeight: 600, gap: "10px", textTransform: "capitalize", borderRadius:'10px', "&:hover": { opacity: 0.9, backgroundColor: "#6e4ea1" }}}>
                  <Photo sx={{marginRight:'10px'}}/>
                    { animalImage.name !== "" ?
                      <div>
                        <img src={animalImage.url} alt="animal-preview" className='w-44 rounded-lg'/> 
                      </div>
                      :
                      <h1>Actualizar Foto</h1>
                    }
                    <input 
                      hidden
                      accept='image/*'
                      type="file"
                      name="photo"
                      onChange={(e) => {
                        // @ts-ignore
                        handleImageChange(e.target.files[0])
                      }}
                    />
                </Button>
                <input 
                  hidden
                  type="text"
                  value={id}
                  {...register("_id")} 
                />
                { animalImage.name !== "" &&
                  <Button type='submit' sx={{ flex: 1, padding: "10px 5px", width: "100%", minWidth: 100, backgroundColor:"green", color:"#fcfcfc", fontSize: 16, fontWeight: 600, gap: "10px", textTransform: "capitalize", borderRadius:'10px', "&:hover": { opacity: 0.9, backgroundColor: "green" }}}>
                    <RiSendPlaneFill />
                    Enviar
                  </Button>
                }
              </Box>
            }       


            <Box className='flex w-full gap-2'>
              <CustomButton title={'Descargar QR'} fullWidth backgroundColor={"#6e4ea1"} color={"#fcfcfc"} icon={<HiOutlineDownload/>} handleClick={handleDownloadPdf}/>
            </Box>
            <Stack width="100%" direction="row" flexWrap="wrap" gap={2}>
              <CustomButton  title={'Ver Perfil Público'} backgroundColor="#e7defe" color="purple" fullWidth icon={<Pets />} handleClick={() => (navigate(`/registro/${animalDetalles._id}`))}/>
            </Stack>
          </Box>
          {animalDetalles.sos?
            <Box className='w-100 flex flex-col p-4 justify-center items-center bg-white gap-1 rounded-2xl shadow-2xl'>
              <Stack direction='row' justifyContent='space-evenly' width='100%' alignItems='center'>
                <Sos sx={{fontSize:'60px', color:'red'}}/>
                <Stack gap={1}>
                  <Typography sx={{textAlign:'left'}}>Fecha de reporte: <br /> <b>{animalDetalles.fechaReporte}</b></Typography>
                </Stack>
              </Stack>
              <Stack direction='row' gap={0.5} alignItems='center'>
                <Phone sx={{fontSize:'18px', bgcolor:'#6e4ea1', color:'#fcfcfc', padding: '3px', borderRadius:'50%'}}/>
                <a href={`tel:${animalDetalles.telEmergencia}`}>{animalDetalles.telEmergencia}</a>
              </Stack>
              <Map lat={animalDetalles.ultimaUbicacion.latitud} lng={animalDetalles.ultimaUbicacion.longitud}/>
            </Box> 
            : <></>
          }
      </Box>
{/* ------------------ Info Card ------------------ */}
        <div className='max-w-sm bg-white shadow-2xl rounded-2xl pb-10' id='proyectojuskas' >
          {/* Fotografía */}
          <div className="flex-1">
            <img 
              src={animalDetalles.photo} 
              alt={animalDetalles.nombre} 
              style={{ objectFit: "cover"}} 
              className="max-w-sm rounded-2xl"
            />
            <div className='px-3 mt-[10px]'>
          {/* Box Informacion */}
            <div className='flex w-full justify-between my-[2]'>
                <Stack direction="column" gap={0.5} alignItems="center" py={2} borderRadius='20px' bgcolor="#f0f0f0" height="fit-content" minWidth={110}>
                  <Typography fontSize={15} textTransform="capitalize">Nacimiento</Typography>   
                  <Typography fontSize={15} fontWeight={600} textTransform="capitalize">{animalDetalles.birthdate}</Typography>   
                </Stack>
                <Stack direction="column" gap={0.5} alignItems="center" py={2} borderRadius='20px' bgcolor="#f0f0f0" height="fit-content" minWidth={100}>
                  <Typography fontSize={15} textTransform="capitalize">Peso</Typography>   
                  <Typography fontSize={15} fontWeight={600} textTransform="capitalize">{animalDetalles.peso} kg</Typography>   
                </Stack>
                <Stack direction="column" gap={0.5} alignItems="center" py={2} borderRadius='20px' bgcolor="#f0f0f0" height="fit-content" minWidth={110}>
                  <Typography fontSize={15}  textTransform="capitalize">Sexo</Typography>   
                  <Typography fontSize={15} fontWeight={600} textTransform="capitalize" display='flex' gap={1} alignItems='center'>{animalDetalles.sexo === 'masculino' ? <BsGenderMale/>: <BsGenderFemale/>} {animalDetalles.sexo === 'masculino' ? 'macho': 'hembra'}</Typography>   
                </Stack>
            </div>

            <Stack direction="row" alignItems="end" justifyContent="center" flexWrap='wrap' sx={{width:'100%'}}>
  {/* Primer Columna */}
              <Stack direction="column" gap={1.5} sx={{width:'100%', paddingX:1, marginTop:1}}>
                <Stack direction="row" gap={1} alignItems='center'>
                  {animalDetalles.especie === 'perro'? <TbDog fontSize={42} color='#6e4ea1'/> : <TbCat fontSize={42} color='#6e4ea1'/>}
                  <Typography fontSize={32} fontWeight={700}> {animalDetalles.nombre}</Typography>
                </Stack>
                <Stack direction="row" gap={1} alignItems='center'>
                  {animalDetalles.procedencia === 'compañia'? <BsHouseHeart fontSize={26}/> : animalDetalles.procedencia === 'comunal' ? <BsHouses fontSize={24}/> : <BsHouseSlash fontSize={24}/>}
                  <Typography fontSize={20} fontWeight={700} textTransform='capitalize'>
                    {animalDetalles.procedencia === 'compañia'? 'Animal de Compañía' : animalDetalles.procedencia === 'comunal' ? 'Tenencia Comunal' : 'Calle'}
                  </Typography>
                </Stack>
                <Typography fontSize={20} fontWeight={700} textTransform='capitalize'>{animalDetalles.raza === 'raza' ? animalDetalles.razaUnica : animalDetalles.raza}</Typography>
                { animalDetalles.medio !== undefined &&
                  <Typography fontSize={20} textTransform="capitalize">Procedencia: <b>{animalDetalles.medio}</b></Typography>
                }
                <Typography fontSize={20} textTransform="capitalize">Color: <b>{animalDetalles.color}</b></Typography>
              </Stack>
  {/* Segunda Columna */}
              <Stack direction="column" alignItems={{lg:'end', md:'end', xs: 'center'}} gap={1.5} mt={1.5}>
                <Stack direction="row" gap={0.5} alignItems="center" px={1.5} py={0.5} borderRadius={1} bgcolor={`${animalDetalles.esterilizado === "si" ? '#90ee90': '#ffc9bb'}`}  height="fit-content">
                  <LocalHospital
                  sx={{ fontSize: 18, color:`${animalDetalles.esterilizado === "si" ? 'green': 'red'}`}}
                  />
                  <Typography color={`${animalDetalles.esterilizado === "si" ? 'green': 'red'}`} fontSize={18} fontWeight={300}>{animalDetalles.esterilizado === 'si' ? 'Esterilizado' : 'No esterilizado'}</Typography>
                </Stack>
                { animalDetalles.nfc && animalDetalles.nfc !== '' &&
                  <Stack minWidth={200} direction="column" px={1} py={1} borderRadius='10px' alignItems='center' color="#808191" bgcolor="#f0f0f0" gap='2px' fontWeight={600}>
                    <Stack direction='row' gap={0.5} alignItems='center'> 
                      <HealthAndSafety sx={{fontSize:"24px", color:"#6e4ea1"}}/>
                      <Typography fontSize={18} fontWeight={800}> NFC </Typography>
                    </Stack>
                    <Typography fontSize={16} fontWeight={300}> {animalDetalles.nfc} </Typography>
                  </Stack>
                }
                { animalDetalles.tag[0] &&
                  <Stack minWidth={200} direction="column" px={1} py={1} borderRadius='10px' alignItems='center' color="#808191" bgcolor="#f0f0f0" gap='2px' fontWeight={600}>
                    <Stack direction='row' gap={0.5} alignItems='center'> 
                      <HealthAndSafety sx={{fontSize:"24px", color:"#6e4ea1"}}/>
                      <Typography fontSize={18} fontWeight={800}> NFC </Typography>
                    </Stack>
                    <Typography fontSize={16} fontWeight={300}> {animalDetalles.tag[0].tag} </Typography>
                  </Stack>
                }
                { animalDetalles.rfid !== undefined && animalDetalles.rfid !== ''?
                  <Stack minWidth={200} direction="column" px={1} py={1} borderRadius='10px' alignItems='center' color="#808191" bgcolor="#f0f0f0" gap='2px' fontWeight={600}>
                    <Stack direction='row' gap={0.5} alignItems='center'> 
                      <HealthAndSafety sx={{fontSize:"24px", color:"#6e4ea1"}}/>
                      <Typography fontSize={18} fontWeight={800}> RFID </Typography>
                    </Stack>
                    <Typography fontSize={16} fontWeight={300}> {animalDetalles.rfid} </Typography>
                  </Stack>
                : <></> }
                </Stack>
              </Stack>

              <div className="flex flex-col items-center justify-center gap-4 mt-10">
                <div className="flex gap-5 items-center">
                  <img src={ayuntamiento} alt="ayuntamiento" className="w-20"/>
                  <img src={juskas} alt="juskas" className="w-28"/>
                </div>
                <img src={morelialg} alt="moreliabrilla" className="w-44"/>
              </div>
            </div>
          </div>
        </div>
{/* ------------------ QR Card ------------------ */}
        <Box width="100%" flex={1} display="flex" flexDirection="column" gap="20px" className='max-w-sm' ref={printRef}>
          <Box className='w-100 flex flex-col p-4 justify-center items-center gap-1 rounded-2xl shadow-2xl bg-gradient-to-r from-purple-800 to-[#E100FF]'>
            <div className="w-full py-4 px-12 gap-2 flex text-white items-center">
              {animalDetalles.especie === 'perro'? <TbDog fontSize={42}/> : <TbCat fontSize={42}/>}
              <h1 className="text-white font-bold text-2xl text-center">{animalDetalles.nombre}</h1>
            </div>
            <div className="bg-white p-6 rounded-2xl">
              <QRCode value={`https://juskas.morelia.gob.mx/${animalDetalles.nfc !== undefined ? animalDetalles.nfc : 'registro/'+animalDetalles._id}`}/>
            </div>
            <h1 className="text-white font-light uppercase text-sm text-center">{animalDetalles._id}</h1>
            <div className="flex justify-between w-full px-12 py-4 items-center">
              <img src={whitejuskas} alt='juskas' className="w-14"/>
              <img src={whiteayuntamiento} alt="ayuntamiento" className="w-12" />
              <img src={whitemoreliabrilla} alt='juskas' className="w-14"/>
            </div>
          </Box>
        </Box>

      </Box>
    </Box>
  )
}

export default AnimalesDetalles