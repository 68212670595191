import { useState, useEffect } from 'react';
import { Box, FormControl, FormHelperText, TextField, Stack, FormGroup, FormControlLabel, Checkbox, Select, MenuItem } from '@mui/material';
import { Sos, ToggleOffOutlined, ToggleOnRounded} from '@mui/icons-material';
import { DatePicker} from '@mui/x-date-pickers';
import { format, parse } from 'date-fns';
import CustomButton from './CustomButton';
import { FormEditProps } from 'interfaces/common';
import AddLocation from './AddLocation';
import { ayuntamiento, moreliabrilla, sosjuskas } from 'assets';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const FormEdit = ({type, register, setValue, formLoading, handleSubmit, onFinishHandler, handleImageChange, watch, animalImage} : FormEditProps) => {
  const navigate  = useNavigate();

  const [state, setState] = useState({ sos: false });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  const { sos } = state;
  
  // date handling
  const dateFormat = 'dd/MM/yyyy';
  const today = format( new Date(), dateFormat );
  const maxDate = new Date()
  const referenceDate = new Date( 1997, 0, 1, 0, 0, 0 );

  // sos date
  const [selectedDateSos, setSelectedDateSos] = useState(today)

  const datefnsDateSos = parse( selectedDateSos, dateFormat, referenceDate );
  
  const handleDateSos = (newValue: Date) => {
    let formatedDateString = format( newValue, dateFormat );
    setSelectedDateSos(formatedDateString)
  }

  useEffect(() => {
    setValue('fechaReporte', selectedDateSos)
  }, [setValue, selectedDateSos]);

  // location
  const [location, setLocation] = useState({ lng:-101.1923185, lat:19.702423 })

  useEffect(() => {
    setValue('latitud',location.lat)
    setValue('longitud',location.lng)
  }, [setValue, location]);

  return (
  <div className='min-w-full min-h-full'>
    <Box className='md:max-w-screen-sm max-w-xs p-7 rounded-2xl shadow-2xl ml-auto mr-auto'>
      <Box display='flex' justifyContent='space-between' alignItems={{md:'center',xs:'start'}} className='mt-5'>
        <Box><img src={ayuntamiento} alt='logo' className='md:w-20 w-10'/></Box>
          <img src={sosjuskas} alt="sosjuskas" className='md:w-36 w-24'/>
        <Box><img src={moreliabrilla} alt='logo' className='md:w-24 w-12'/></Box>
      </Box>
    
      <Box mt={2.5} borderRadius="15px" paddingBottom="10px" paddingX="20px" bgcolor="#fcfcfc">
        <form 
          style={{ width: '100%', display: 'flex', flexDirection: 'column', gap:'20px'}}
          onSubmit={handleSubmit(onFinishHandler)}
        >           
          <Box className='ml-auto mr-auto'>
  {/* sos --------------------------------------------- */}
            <FormControl sx={{flex:1, alignItems:'center', flexDirection:'row'}}>
              <FormHelperText style={{color:sos ? 'red' : ''}}><Sos sx={{fontSize:'50px'}}/> </FormHelperText>
              <FormGroup>
                <FormControlLabel 
                  sx={{ color:"#9f9f9f"}}
                  label={sos ? 'Desactivar': 'Activar'}
                  value={sos}
                  control={
                    <Checkbox color='info' checked={sos}  onChange={handleChange} icon={<ToggleOffOutlined />} checkedIcon={<ToggleOnRounded />} name="sos" />
                  }
                  {...register('sos', {required: false})}
                />              
              </FormGroup>
            </FormControl>
          </Box> 
{/* ------------------------------------------------------- */}
{/* ubicacion --------------------------------------------- */}
          {sos ?
          <Stack direction="row" gap={4} flexWrap='wrap'>
{/* telEmergencia --------------------------------------------- */}    
            <FormControl sx={{flex:1, minWidth:'200px'}}>
              <TextField 
                fullWidth
                required
                placeholder='* Teléfono de Emergencia (10 dígitos)'
                id="outlined-basic"
                color="info"
                variant="outlined"
                type="number"
                inputProps={{ maxLength:10 }}
                {...register('telEmergencia', {required: false})}
              />
            </FormControl>
{/* fecha --------------------------------------------- */}
            <FormControl sx={{flex:1, minWidth:'200px'}}>
              <DatePicker 
                label="Fecha de Desaparicion"
                value={datefnsDateSos}
                minDate={referenceDate}
                maxDate={maxDate}
                onChange={(newValueSos) => handleDateSos(newValueSos as Date)} 
              />
            </FormControl>
            <FormControl sx={{flex:1, minWidth:'200px'}}>
              <TextField
                label="Fecha Actual del Reporte"
                disabled
                required
                fullWidth
                value={selectedDateSos}
                {...register('fechaReporte', {required: false})}    
              />
            </FormControl>
          </Stack> 
          : <></>
          }
          {sos && <AddLocation location={location} setLocation={setLocation}/>}
            <TextField 
              sx={{display: 'none'}} 
              value={location?.lat}
              {...register('latitud', {required: false})}
            />
            <TextField 
              sx={{display: 'none'}} 
              value={location?.lng}
              {...register('longitud', {required: false})}
            />
{/* ------------------------------------------------------- */}
{/* esterilizado --------------------------------------------- */}
          <Stack>
            <FormControl sx={{flex:1, minWidth:'100px'}}>
              <Select
                variant= "outlined"
                color="info"
                displayEmpty
                className='textColor'
                defaultValue=""
                inputProps={{ "aria-label" : "Whithouth label"}}
                {...register('esterilizado', {required: false})}
              >
                <MenuItem  value="" disabled>Esterilizado *</MenuItem>
                <MenuItem value="si">Si</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <TextField 
                sx={{display: 'none'}} 
                // value={lastSegment}
                {...register('_id', {required: false})}
              />
            </FormControl>
          </Stack>
          <CustomButton 
            type="submit"
            title={'Actualizar Datos'}
            backgroundColor="purple"
            color="#fcfcfc"
          />
          <CustomButton title={'Cancelar'} backgroundColor="#b20027" color="#FCFCFC" fullWidth icon={<FaArrowLeft />} handleClick={() => (navigate('/animales'))} />
        </form>
      </Box>
    </Box>
  </div>
  )
}

export default FormEdit

// {/* ------------------------------------------------------- */}
// {/* photo --------------------------------------------- */}
// <Stack direction="column" gap={1} justifyContent="center" mb="2" >
// <Stack direction="row" gap={2}>
//   <Button component="label" sx={{width: 'fit-content', color: '#fff', textTransform:'capitalize', fontSize: 16, fontWeight: 500, background:'#8e6fbd', justifyContent: "center", padding: "16px", borderRadius: "12px", minHeight: "56px", "&:hover": { background: 'purple'}}}>

//     <AddAPhotoRoundedIcon sx={{marginRight:'10px'}}/>
//     { animalImage.name !== "" ?
//       <div>
//         <img src={animalImage.url} alt="animal-preview" className='w-44 rounded-lg'/> 
//       </div>
//       :
//       <h1>Cambiar Foto *</h1>
//     }
//     <input 
//       hidden
//       accept='image/*'
//       type="file"
//       onChange={(e) => {
//         // @ts-ignore
//         handleImageChange(e.target.files[0])
//       }}
//     />
//     {/* Lo que utilizo para mostrar el nombre de archivo lo que quiero es previsualizar la imagen */}
//   </Button>
// </Stack>
// </Stack>