import { Box } from "@mui/material"
import ReactMapGL, { Marker } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { MapProps } from "interfaces/animal"
// Para que Funcione el Mapa en Producción -----------
// import mapboxgl from 'mapbox-gl';
// import MapboxWorker from 'mapbox-gl/dist/mapbox-gl-worker';

// // eslint-disable-next-line import/no-webpack-loader-syntax
// mapboxgl.workerClass = MapboxWorker;
// ---------------------------------------------------------

const Map = ({lat,lng}: MapProps) => {
  return (
    <Box
    sx={{
        height:200,
        width:'100%',
        position:'relative',
        overflow: 'hidden',
        borderRadius: '20px',
    }}
    >
        <ReactMapGL
            mapboxAccessToken={process.env.REACT_APP_MAP_TOKEN}
            initialViewState={{
                longitude:lng,
                latitude:lat,
                zoom:16
            }}
            mapStyle='mapbox://styles/mapbox/streets-v11'
        >
            <Marker 
            longitude={lng}
            latitude={lat}
            />
        </ReactMapGL>
    </Box>
  )
}

export default Map