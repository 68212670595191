import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ErrorID from 'components/error';

export const PublicNFC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                // TODO: Actualizar ruta
                const res = await axios.get(`https://juskas.onrender.com/api/v1/tag/show/${id}`);
                navigate(`/registro/${res.data.pet[0]}`)
            } catch (error) {
                setError(true)
            }
        }
        fetchData();
    },[id , navigate])

    if (error) {
        return   (
          <ErrorID />
    );
    }

    return null; // Este componente no renderiza nada, ya que realiza una redirección.
};