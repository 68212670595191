import { Box, Stack } from '@mui/material';
import { useSpring, animated } from "react-spring";

interface NumberProps {
    n: number | null,
}

interface NumberCardProps {
    stat: number | null,
    handleClick?: () => void
}

function Number({n}:NumberProps) {
    const {number} = useSpring({
        from: {number: 0},
        number:n,
        delay: 200,
        config: {mass: 1, tension: 20, friction: 10}
    });
    return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>
}

const NumberCard = ({stat}: NumberCardProps) => {
  return (
  <Stack>
      <Box fontWeight={700} fontSize='70px'><Number n={stat}/></Box>
  </Stack>
  )
}

export default NumberCard