import { cat } from "assets";
import { Variants, motion } from "framer-motion"
import { slideLeft, slideRight } from "utils/motion";
import { AiFillCloseCircle, AiFillPlayCircle } from 'react-icons/ai';
import { Button, Fade, Modal } from "@mui/material";
import { vidJuskas } from "assets";
import { useState } from "react";

const Left: Variants = slideLeft('left', 'spring', 0, 1)
const Right: Variants = slideRight('right', 'spring', 0, 1)

export const Info = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };
    
    return (
      <div className="flex h-fit py-20 items-center flex-wrap justify-between min-h-screen">
        <motion.div 
          className="flex flex-col lg:text-left md:text-left text-center lg:items-start md:items-start items-center"
          variants={Left}
          initial="hidden"
          whileInView="show"
        >
          <h1 className="lg:text-[3.5rem] md:text-[3.5rem] text-[3rem] font-bold">Ellos brillan</h1>
          <h2 className="lg:text-[1.7rem] md:text-[1.7rem] text-[1.45rem] font-bold">y nos hacen brillar más</h2>
          <p className="lg:max-w-[40rem] my-10 leading-9 text-[1.2rem] font-light">
            Proyecto Juskas, es un esfuerzo integral de protección animal, que la administración municipal que encabeza Alfonso Martínez Alcázar ha decidido implementar. <br /><br />
            Da certeza de la identidad del animal de compañía, así como, protección en caso de pérdida y apoyo a través de un microchip con identificador único.
          </p>
          <a href="/register" className="font-[400] bg-gradient-to-r from-black to-dorado text-white p-4 px-8 w-44 rounded-xl flex justify-center hover:scale-90 hover:duration-200"><button>Iniciar Ahora</button></a>
        </motion.div>
        <motion.div 
          className="flex flex-col items-center mt-10 lg:mt-0"
          variants={Right}
          initial="hidden"
          whileInView="show"
        >
          <img src={cat} alt="cat" className="lg:max-w-[40rem]"/>
          <button className="flex -mt-10 items-center gap-2" onClick={handleOpen}>
            <AiFillPlayCircle className="text-[2.5rem] text-dorado"/>
            <p>Reproduce el video <br /> y ve como funciona</p>
          </button>
        </motion.div>
        <Modal
          open={open}
          onClose={handleClose}
          closeAfterTransition
        >
          <Fade in={open}>
            <div className="flex justify-center items-center w-full h-full">
              <div className="bg-white w-full h-full md:max-w-lg max-w-xs md:max-h-[22rem] max-h-[15rem] rounded-2xl p-4">
                <div className="flex justify-end">
                  <Button onClick={handleClose}><AiFillCloseCircle className="text-2xl text-moradoDark hover:text-morado" /> </Button>
                </div>
                <video src={vidJuskas} controls className="rounded-2xl"/>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    )
  }