import Lottie from 'lottie-react';
import errorAnimation from '../../assets/loading.json'
import { juskas, moreliabrilla } from "assets";
import { MdOutlineArrowBack } from 'react-icons/md';

const ErrorPage = () => {

  return ( 
    <div className='flex flex-col gap-1  min-h-screen px-6 max-w-md ml-auto mr-auto'>
        <div className="flex gap-5 items-center justify-evenly my-5">
            <img src={moreliabrilla} alt="ayuntamiento" className="w-24"/>
            <img src={juskas} alt="juskas" className="w-24"/>
        </div>
        <h1 className='text-xl font-medium text-center'>Hubo un error en la página que estás buscando</h1>
        <div className='flex items-center mt-4 justify-center'>
          <a href='/' className='text-sm bg-morado text-white px-3 py-3 rounded-[10px] duration-300 hover:bg-moradoDark flex items-center gap-2'><MdOutlineArrowBack /> Volver al inicio </a>
        </div>
        <div className='flex items-center flex-col mb-2'>
            <Lottie animationData={errorAnimation} className="max-w-sm"/>
            <h3 className='text-center font-light'><span className='font-bold'>Ellos brillan</span> y nos hacen brillar más</h3>
        </div>
    </div>
  )
}

export default ErrorPage