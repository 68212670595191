import { GpsFixedSharp, HealthAndSafety, LocalHospital } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Typography,Card, CardContent, Stack } from "@mui/material";

import { TbCat, TbDog} from 'react-icons/tb'
import { MdSos} from 'react-icons/md'

import { AnimalCardProps } from "interfaces/animal";

const PetCard = ({ id, nfc, rfid, procedencia, nombre, especie, esterilizado, sos, photo}: AnimalCardProps) => {
  return (
    <Card
      component={Link}
      to={`/animales/show/${id}`}
      sx={{
        width: '330px',
        borderRadius: '12px',
        backgroundColor: 'transparent',
        transition: 'transform 0.3s ease',
        ':hover': {
          transform: 'scale(1.05)',
        },
      }}
      elevation={0}
    >
      <div className="px-5 py-2 cursor-pointer rounded-xl bg-gradient-to-l from-[#9c27b0] to-blue-500 ">
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems:'start', gap: '10px', paddingX: '5px'}} >
          <div className="flex gap-3 items-center">
{/* Nombre */}
            <img src={photo} alt="animal" className="w-20 h-20 overflow-hidden object-cover rounded-full"/>
            <div className="flex flex-col gap-3 mb-2">
              <Stack direction="column" gap={0}>
                <Stack direction='row' alignItems='center' gap='5px'>
                  <Typography fontSize={16} fontWeight={600} color="white">{nombre}</Typography>
                  { sos ? <MdSos style={{fontSize:32, color:'red'}}/>: ''} 
                </Stack>
              </Stack>
{/* Microchips */}
              <Stack direction="row" gap={1} justifyContent='start'>
                  { nfc !== undefined && nfc !== ''?
                    <div className="w-10 h-10 rounded-lg flex flex-col items-center justify-center text-white border-2 border-white">
                      <HealthAndSafety sx={{fontSize:"14px"}}/>
                      <Typography fontSize={10} fontWeight={800}> NFC </Typography>
                    </div>
                    : <></> }
                  { rfid !== undefined && rfid !== ''?
                    <div className="w-10 h-10 rounded-lg flex flex-col items-center justify-center text-white border-[1px] border-white">
                      <HealthAndSafety sx={{fontSize:"14px"}}/>
                      <Typography fontSize={10} fontWeight={800}> RFID </Typography>
                    </div>
                  : <></> }
                </Stack>
            </div>

          </div>
  {/* Procedencia, Especie */}
          <Stack direction="row" gap={1} width='100%' fontWeight={300} color="#808191">
            <Stack direction="row" gap={0.5} alignItems="center" px={1} py={0.5} borderRadius={1} bgcolor="lavender" height="fit-content">
              <GpsFixedSharp 
                sx={{ fontSize: 16, color:'purple'}}
                />
              <Typography fontSize={12} textTransform="capitalize" color={'purple'}>{procedencia}</Typography>
            </Stack>
            <Stack direction="row" gap={0.5} alignItems="center" px={1} py={0.5} borderRadius={1} bgcolor="lavender" height="fit-content">
              {especie === 'perro' ? <TbDog  style={{ fontSize: 16, color: 'purple'}}/> : <TbCat  style={{ fontSize: 16}}/>}
              <Typography fontSize={12} textTransform="capitalize" color={'purple'} >{especie}</Typography>
            </Stack>
            {/* Esterilizado */}
            { esterilizado === 'si' &&
              <Stack direction="row" gap={0.5} alignItems="center" px={1} py={0.5} borderRadius={1} bgcolor="#90ee90" height="fit-content">
                  <LocalHospital sx={{ fontSize: 16, color:"green" }}/>
                  <Typography color="green" fontSize={12}>Esterilizado</Typography>
              </Stack>
            }
          </Stack>
        </CardContent>
      </div>
    </Card>
  )
}

export default PetCard