// Form Options
export const medioAnimal = [
  {
    value:'adopcion',
    text:'Adopción',
  },  
  {
    value:'rescate',
    text:'Rescate',
  },  
  {
    value:'casa',
    text:'Nació en Casa',
  },    
  {
    value:'regalo',
    text:'Regalo',
  },  
  {
    value:'compra',
    text:'Compra',
  },
]

export const colores = [
  {
    value:'amarillo',
    text: 'Amarillo',
  },
  {
    value:'blanco',
    text: 'Blanco',
  },
  {
    value:'café',
    text: 'Café',
  },
  {
    value:'chocolate',
    text: 'Chocolate',
  },
  {
    value:'crema',
    text: 'Crema',
  },
  {
    value:'gris',
    text: 'Gris',
  },
  {
    value:'negro',
    text: 'Negro',
  },
  {
    value:'otro',
    text: 'Otro',
  },
]

export const razasGato = [
  {
    value: "domestico",
    text:"Gato Doméstico"
  },
  {
    value: "siames",
    text:"Siamés"
  }, 
  {
    value: "persa",
    text:"Persa"
  }, 
  {
    value: "britishshorthair",
    text:"British Shorthair"
  }, 
  {
    value: "mainecoon",
    text:"Maine Coon"
  }, 
  {
    value: "otra",
    text:"Otra"
  },]

export const razasPerro = [ 
  {
    value: "beagle",
    text:"Beagle"
  }, 
  {
    value: "bichon",
    text:"Bichón Frisé"
  }, 
  {
    value: "border",
    text:"Border Collie"
  },  
  {
    value: "boxer",
    text:"Bóxer"
  }, 
  {
    value: "bullterrier",
    text:"Bull Terrier"
  }, 
  {
    value: "bulldog",
    text:"Bulldog Inglés"
  }, 
  {
    value: "bulldogfrances",
    text:"Bulldog Francés"
  }, 
  {
    value: "caniche",
    text:"Caniche"
  }, 
  {
    value: "chihuahua",
    text:"Chihuahua"
  }, 
  {
    value: "chowchow",
    text:"Chow Chow"
  }, 
  {
    value: "cockerspaniel",
    text:"Cocker Spaniel"
  }, 
  {
    value: "Dachshund",
    text:"Dachsund (Perro salchicha)"
  }, 
  {
    value: "dalmata",
    text:"Dálmata"
  }, 
  {
    value: "doberman",
    text:"Dobermá Pinscher"
  },     
  {
    value: "golden",
    text:"Golden Retriever"
  }, 
  {
    value: "grandanes",
    text:"Gran Danés"
  }, 
  {
    value: "husky",
    text:"Husky Siberiano"
  }, 
  {
    value: "labrador",
    text:"Labrador Retriever"
  }, 
  {
    value: "pastoraleman",
    text:"Pastor Alemán"
  }, 
  {
    value: "pomerania",
    text:"Pomerania"
  }, 
  {
    value: "poodle",
    text:"Poodle"
  }, 
  {
    value: "pug",
    text:"Pug"
  }, 
  {
    value: "rottweiler",
    text:"Rottweiler"
  }, 
  {
    value: "sanbernardo",
    text:"San Bernardo"
  }, 
  {
    value: "schnauzer",
    text:"Schnauzer Miniatura"
  }, 
  {
    value: "sharpei",
    text:"Shar Pei"
  }, 
  {
    value: "shihtzu",
    text:"Shih Tzu"
  },
  {
    value: "terrierescoces",
    text:"Terrier Escocés"
  }, 
  {
    value: "yorkshireterrier",
    text:"Yorkshire Terrier"
  }, 
  {
    value: "otra",
    text:"Otra"
  },
]