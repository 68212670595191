import { Authenticated, Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import './index.css'
import { RefineSnackbarProvider, Sider, ThemedLayoutV2 } from "@refinedev/mui";
import { CssBaseline, GlobalStyles } from "@mui/material";
import routerBindings, {
  CatchAllNavigate,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";
import { AppIcon } from "components/app-icon";
import { ForgotPassword } from "pages/forgotPassword";
import { Login } from "pages/login";
import { Home } from "pages/home";
import { Register } from "pages/register";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { authProvider } from "./authProvider";
import {  Header as Head } from "./components";
import { ColorModeContextProvider } from "./contexts/color-mode";
import Animales from "pages/animales/animales";
import CrearAnimal from "pages/animales/crear-animal";
import EditarAnimal from "pages/animales/editar-animal";
import AnimalesDetalles from "pages/animales/animales-detalles";
import AnimalesPublico from "pages/publico/animales-publico";
import ReporteSos from "pages/publico/sos";
import Landing from "pages/landing";
import RegistroExitoso from "pages/animales/registro-exitoso";
import Faqs from "pages/faqs";
import { FaHandHoldingHeart } from "react-icons/fa";
import { MdPets } from "react-icons/md";
import { BiHomeHeart } from "react-icons/bi";
import { IoIosSchool } from "react-icons/io";
import Adopciones from "pages/adopciones";
import Tutoriales from "pages/tutoriales";
import { PublicNFC } from "pages/publico/id-nfc";
import { AnimalesNFC } from "pages/publico/animales-nfc";
import ErrorPage from "components/error/common";
import { UpdatePasswordPage } from "pages/updatePassword";

function App() {

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <CssBaseline />
          <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
          <RefineSnackbarProvider>
            <Refine
              dataProvider={dataProvider("https://juskas.onrender.com/api/v1")}
              routerProvider={routerBindings}
              resources={[
                {
                  name: "juskas",
                  list: "/",
                  meta: {
                    icon: <FaHandHoldingHeart />
                  }
                },
                {
                  name: "animales",
                  list: "/animales",
                  create: "/animales/create",
                  edit: "/animales/edit/:id",
                  show: "/animales/show/:id",
                  meta: {
                    icon: <MdPets />
                  },
                },
                {
                  name: "Adopciones",
                  list: "/adopciones",
                  meta: {
                    icon: <BiHomeHeart />
                  },
                },
                {
                  name: "Tutoriales",
                  list: "/tutoriales",
                  meta: {
                    icon: <IoIosSchool />
                  },
                },
              ]}
              authProvider={authProvider}
              options={{
                syncWithLocation: true,
                warnWhenUnsavedChanges: true,
              }}
            >
              <Routes>
{/* Authenticated Routes */}
                <Route
                  element={
                    <Authenticated key="authenticated-outer" fallback={<CatchAllNavigate to="/home" />}>
                      <ThemedLayoutV2 Sider={Sider} Header={Head} Title={({ collapsed }) => ( <AppIcon collapsed={collapsed} /> )} >
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                  }
                >
                  {/* Landing */}
                  <Route path='/'
                      index
                      element={<Landing />}
                  />
                  {/* Animales */}
                  <Route path="/animales">
                    <Route index element={<Animales />} />
                    <Route path="create" element={<CrearAnimal />} />
                    <Route path="edit/:id" element={<EditarAnimal />} />
                    <Route path="show/:id" element={<AnimalesDetalles />} />
                    <Route path="registro-exitoso" element={<RegistroExitoso />} />
                  </Route>
                  {/* Adopciones */}
                  <Route path="/adopciones">
                    <Route index element={<Adopciones />} />
                  </Route>
                  {/* Tutoriales */}
                  <Route path="/tutoriales">
                    <Route index element={<Tutoriales />} />
                  </Route>
                </Route>
{/* Authenticated Routes Header and Sider */}
                <Route
                    element={
                      <Authenticated key="authenticated-inner">
                        <ThemedLayoutV2 Header={Head} Sider={Sider} Title={({ collapsed }) => ( <AppIcon collapsed={collapsed}/>)}>
                          <Outlet />
                        </ThemedLayoutV2>
                      </Authenticated>
                    }
                >
                  <Route path="*" element={<ErrorPage />} />
                </Route>
{/* Public Routes */}
                <Route>
                  <Route path="/home" element={<Home />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/sos" element={<ReporteSos />} />
                  <Route path="/faqs" element={<Faqs />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/update-password/:id/:token" element={<UpdatePasswordPage />} />
                  <Route path="/registro/:id" element={<AnimalesPublico />} />
                  <Route path="/id/:id" element={<PublicNFC />} />
                  <Route path="/:nfc" element={<AnimalesNFC />} />
                </Route>
              </Routes>

              <RefineKbar />
              <UnsavedChangesNotifier />
            </Refine>
          </RefineSnackbarProvider>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
