import { Aviso, Terminos, whiteayuntamiento, whitejuskas, whitemoreliabrilla } from "assets"
import { AiOutlineWhatsApp } from "react-icons/ai"
import { FiFacebook, FiPhone } from "react-icons/fi"
import { MdLocationPin } from "react-icons/md"
import { Variants, motion } from "framer-motion"
import { slideTop } from "utils/motion";

const Up: Variants = slideTop('up', 'spring', 0, 1)

export const Footer = () => {
    return (
      <motion.div 
        className="flex items-end"
        variants={Up}
        initial="hidden"
        whileInView="show"
      >
        <div className="mb-10 z-100 w-[35rem] h-[33rem] bg-cover bg-no-repeat rounded-3xl lg:flex flex-col space-between filter hidden bg-paw-pattern">
          <div className="flex justify-center mt-5">
            <img src={whitejuskas} alt="juskas" className="w-44"/>
          </div>
          <div className="flex justify-between mt-44">
            <img src={whiteayuntamiento} alt="ayuntamiento" className="w-32"/>
            <img src={whitemoreliabrilla} alt="moreliabrilla" className="w-32"/>
          </div>
        </div>

        <div className="lg:-ml-40 bg-gradient-to-l from-black to-dorado p-10 rounded-3xl text-white">
          <div className="lg:pl-40 flex justify-between w-full lg:gap-4 gap-8 flex-wrap">
            <div className="flex flex-col gap-2 lg:w-1/4">
              <h1 className="font-bold mb-5 text-xl">Menú</h1>
              <a href="/register" className="font-light hover:text-dorado hover:duration-200">Regístrate</a>
              <a href="/animales/sos" className="font-light hover:text-dorado hover:duration-200">SOS Juskas</a>
              <a href="/register" className="mt-4 font-[400] bg-gradient-to-r from-black to-dorado text-white p-4 px-8 w-44 rounded-xl flex justify-center hover:scale-90 hover:duration-200"><button>Iniciar Sesión</button></a>
            </div>
            <div className="flex flex-col gap-2  lg:w-2/4">
              <h1 className="font-bold mb-5 text-xl">Nosotros</h1>
              <p>El Centro de Atención Animal de Morelia es una institución encargada de brindar servicios para el cuidado y protección de los animales en el municipio de Morelia</p>
              <div className="flex gap-3 mt-10 text-2xl text-dorado">
                <a href="https://www.facebook.com/CAAMorelia/?mibextid=LQQJ4d" rel="noreferrer" target='_blank' className="bg-black p-2 rounded-full hover:bg-white hover:duration-200"><FiFacebook /></a>
                <a href="tel:4433211392" className="bg-black p-2 rounded-full hover:bg-white hover:duration-200"><FiPhone /> </a>
                <a href="https://goo.gl/maps/AFgncmgLcX39iDHR6 "rel="noreferrer" target='_blank' className="bg-black p-2 rounded-full hover:bg-white hover:duration-200"><MdLocationPin /> </a>
                <a href="tel:4433214731" className="bg-black p-2 rounded-full hover:bg-white hover:duration-200"><FiPhone /> </a>
                <a href="https://wa.me/+527298094080 "rel="noreferrer" target='_blank' className="bg-black p-2 rounded-full hover:bg-white hover:duration-200"><AiOutlineWhatsApp /> </a>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <h1 className="font-bold mb-5 text-xl">Contacto</h1>
              <a href={Aviso} target="_blank" rel="noreferrer" className="font-light hover:text-dorado hover:duration-200">Aviso de Privacidad</a>
              <a href={Terminos} target="_blank" rel="noreferrer" className="font-light hover:text-dorado hover:duration-200">Términos y Condiciones</a>
              <a href="https://transparenciaproactiva.morelia.gob.mx/" rel="noreferrer" target='_blank' className="font-light hover:text-dorado hover:duration-200">Transparencia</a>
            </div>
          </div>
          <div className="lg:pl-40 mt-20 text-center">
            <div className="w-full bg-white h-[0.1rem] my-5"></div>
            <a href="https://www.livetech.world" rel="noreferrer" target='_blank' className="font-light lg:text-base md:text-base text-sm">Todos los derechos reservados H.Ayuntamiento de Morelia 2024</a>
          </div>
        </div>
      </motion.div>
    )
  }