import { ArrowBackIos, ArrowForwardIos, Sos } from "@mui/icons-material";
import { Box, Stack } from "@mui/system";
import { ayuntamiento, juskas, whitemorelialg } from "assets";
import { useTable } from "@refinedev/core";
import { useMemo, useState } from "react";
import { Button, MenuItem, Select, TextField, Typography } from "@mui/material";
// SOS Map
import ReactMapGL, { GeolocateControl, Marker, NavigationControl, Popup } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { FcGlobe, FcHighPriority } from "react-icons/fc";
import { FaSearch } from "react-icons/fa";
import { TbCat, TbDog } from "react-icons/tb";
import PetCardPublic from "components/common/PetCardPublic";
import { Helmet } from "react-helmet";

const ReporteSos = () => {
    // Pagination and Filtering
    const {
    tableQueryResult: {data},
    current,
    setCurrent,
    setPageSize,
    pageCount,
    filters, setFilters,
    } = useTable({resource: "animales/sos"});

    const animalesArray = data?.data ?? [];

    const SosArray = animalesArray.filter(item => item.sos )

    // Dynamic Markers
    const [selectedMarker, setSelectedMarker] = useState<number | null>(null);
    const handleMarkerClick = (markerId: number) => {
        setSelectedMarker(markerId);
      };
    const handleCloseClick = () => {
        setSelectedMarker(null);
    };

    // Filter
    const currentFilterValues = useMemo(() => {
        const logicalFilters = filters.flatMap((item) => ('field' in item ? item : []))
        return {
        nombre: logicalFilters.find((item) => item.field === 'nombre')?.value || '',
        procedencia: logicalFilters.find((item) => item.field === 'procedencia')?.value || '',
        especie: logicalFilters.find((item) => item.field === 'especie')?.value || '',
        }
    }, [filters])

    // Search Button
    const [collapsed, setCollapsed] = useState(true)

    const handleToggle = () => {
        setCollapsed(!collapsed);
    };
  return (
    <div className="bg-forms bg-contain bg-no-repeat">
        <Helmet>
          <meta charSet="utf-8" />
          <title>SOS Juskas</title>
          <meta property="og:title" content="SOS Juskas" />
          <meta property="og:description" content="Ve en tiempo real los animales de compañía con reporte SOS en la ciudad de Morelia, haz te reporte y ayuda a los animales a regresar con su dueño."/>
        </Helmet>
        <Box position='absolute' top='0' bgcolor="#6e4ea1" py='10px' px='20px' borderRadius='0 15px 15px 15px'>
          <img src={whitemorelialg} alt="morelia brilla" height='15px'/>
        </Box>  
        <Box position='absolute' top='0' right='0' bgcolor="red" py='5px' px='10px' borderRadius='15px 0 15px 15px '>
            <Sos sx={{fontSize:"50px", color:'#f0f0f0'}}/>
        </Box>
        <Box position='fixed' bottom='0' width='100%' left={0}>
            <Stack fontWeight={300} letterSpacing={1} fontSize='12px'>
                <a href='https://www.livetech.world' rel="no-referrer" style={{borderRadius:'10px 10px 0 0', backgroundColor:"#6e4ea1", color:"#fcfcfc", textAlign:'center'}}>Desarrollado por Live Tech S.A.P.I. de C.V.</a>
            </Stack>
        </Box>
        <Stack direction='row' alignItems='center' justifyContent='center' marginTop={10} gap={1}>
            <FcGlobe fontSize={45}/>
            <Stack>
                <Typography fontSize='25px' fontWeight='900' textAlign='center'>Mapa en Tiempo Real</Typography>
                <Typography fontSize='15px' fontWeight='200' textAlign='center'>Animales con reporte de pérdida o robo</Typography>
            </Stack>
        </Stack>
        <Stack direction='row' alignItems='center' justifyContent='center' marginY='10px' flexWrap='wrap' gap={2}>
            <Stack sx={{bgcolor:'#fcfcfc', padding:'20px', paddingBottom:'20px', borderRadius:'20px'}} direction='column'>
                <Box     
                sx={{
                height:400,
                width:'100%',
                minWidth:'80vw',
                position:'relative',
                overflow: 'hidden',
                borderRadius: '20px',
                }}
                >
                    <ReactMapGL
                    mapboxAccessToken={process.env.REACT_APP_MAP_TOKEN}
                    initialViewState={{
                        longitude:-101.1923185,
                        latitude:19.702423 ,
                        zoom:12
                    }}
                    mapStyle='mapbox://styles/mapbox/streets-v11'
                    >
                    {animalesArray.map((item, index) => (
                        <Stack key={index} >
                            {item.sos ? (
                            <Stack>
                                {selectedMarker === item._id && (
                                <Popup
                                    latitude={item.ultimaUbicacion.latitud}
                                    longitude={item.ultimaUbicacion.longitud}
                                    closeButton={true}
                                    closeOnClick={false}
                                    onClose={handleCloseClick}
                                    anchor="top-right"
                                >
                                    {/* Content of the Popup */}
                                    <Box style={{display:'flex', alignItems:'center', flexDirection:'column', gap:'4px'}}>
                                    <Typography textAlign='center' fontSize='14px' fontWeight={600}>{item.nombre}</Typography>
                                        <a href={`/${item._id}`}><img src={item.photo} alt={item._id} style={{width:'90px', height:'50px', borderRadius:'10px', objectFit:'cover'}}/></a> 
                                    <Typography textAlign='center' fontSize='10px'>Fecha de reporte <br /> <b style={{fontSize:'15px'}}>{item.fechaReporte}</b></Typography>
                                    <Typography textAlign='center' fontSize='10px'>Tel. de emergencia <br /> <b><a href={`tel:${item.telEmergencia}`} style={{fontSize:'15px'}}>{item.telEmergencia}</a></b></Typography>
                                    </Box>
                                </Popup>
                                )}
                                <Marker
                                longitude={item.ultimaUbicacion.longitud}
                                latitude={item.ultimaUbicacion.latitud}
                                onClick={() => handleMarkerClick(item._id)}
                                >
                                {/* Marker Icon */}
                                <Box>
                                    <FcHighPriority fontSize='25px'/>
                                </Box>
                                </Marker>
                            </Stack>
                            ) : (
                            <></>
                            )}
                        </Stack>
                        ))}
                        <NavigationControl position='bottom-right' />
                        <GeolocateControl position="top-left" trackUserLocation />
                    </ReactMapGL>
                </Box>
            </Stack>
        </Stack>
{/* Filter and Pagination */}
        <Box>
{/* ------------------- Filters ------------------- */}
        <Box sx={{ display: "flex", flexWrap:"wrap", gap: 3, justifyContent:'center'}}>
                <Stack direction="row" width="100%">
                <Box mt={2} flexDirection="row" display="flex" width="100%" alignItems="start" flexWrap="wrap" gap={1} justifyContent='center'>
                    <Box display="flex" gap={1} mb={{xs: '10px', sm: 0}} flex-grow={{sm:'1'}} justifyContent='center'>
                    {collapsed ?
                        <Button onClick={handleToggle} className="search-button" sx={{minHeight:'3.94em' ,backgroundColor:'#6e4ea1', borderRadius:2,  "&:hover": { backgroundColor: '#808191'} }}>
                        <FaSearch style={{color: 'fcfcfc', fontSize:'24px'}}/>
                        </Button>
                    :
                        <Stack direction='row'>
                        <TextField 
                            className={`search-bar ${collapsed ? "collapsed" : "expanded"}`}
                            variant="outlined"
                            color="info"
                            placeholder="Buscar por nombre"
                            value={currentFilterValues.nombre}
                            onChange={(e) => {
                            setFilters([
                                {
                                    field: 'nombre',
                                    operator: 'contains',
                                    value: e.currentTarget.value ? e.currentTarget.value : undefined
                                }
                                ])
                            }}
                            />
                        </Stack>
                    }
                    <Button 
                        sx={{backgroundColor:'#6e4ea1', borderRadius:2,  "&:hover": { backgroundColor: '#808191'} }}
                        onClick={() => {
                        setFilters([
                            {
                                field: 'especie',
                                operator: 'eq',
                                value: "perro"
                            }
                            ], 'replace')
                        }}
                    >
                        <TbDog style={{color: 'fcfcfc', fontSize:'24px'}}/>
                    </Button>
                    <Button 
                        sx={{backgroundColor:'#6e4ea1', borderRadius:2,  "&:hover": { backgroundColor: '#808191'} }}
                        onClick={() => {
                        setFilters([
                            {
                                field: 'especie',
                                operator: 'eq',
                                value: "gato"
                            }
                            ], 'replace')
                        }}
                    >
                        <TbCat style={{color: 'fcfcfc', fontSize:'24px'}}/>
                    </Button>
                    </Box>
                </Box>
                </Stack>
            </Box>
            
        {/* ------------------- Cards ------------------- */}
            <Box mt="20px" sx={{ display: "flex", flexWrap:"wrap", gap: 3, justifyContent:'center'}}>
                {SosArray.map((animal) => (
                    <PetCardPublic 
                        key={animal._id}
                        id={animal._id}
                        procedencia={animal.procedencia}
                        rfid={animal.rfid}
                        nfc={animal.nfc}
                        nombre={animal.nombre}
                        peso={animal.peso}
                        sexo={animal.sexo}
                        birthdate={animal.birthdate}
                        especie={animal.especie}
                        raza={animal.raza}
                        razaUnica={animal.razaUnica}
                        esterilizado={animal.esterilizado}
                        color={animal.color}
                        medio={animal.medio}
                        sos={animal.sos}
                        ultimaUbicacion={animal.ultimaUbicacion}
                        fechaReporte={animal.fechaReporte}
                        telEmergencia={animal.telEmergencia}
                        createdAt={animal.createdAt}
                        photo={animal.photo}
                    />
                ))}
            </Box>
        {/* ------------------- Pagination ------------------- */}
            {animalesArray.length > 0 && (
                <Box display="flex" gap={2} mt={3} flexWrap="wrap" justifyContent='center'>
                <Button 
                    sx={{backgroundColor:'#6e4ea1', borderRadius:2,  "&:hover": { backgroundColor: '#808191'} }}
                    onClick={() => setCurrent((prev)=> prev-1 )}
                    disabled={!(current > 1)}
                    >
                    <ArrowBackIos />
                </Button>
                <Box display={{xs: 'hidden', sm: 'flex'}} alignItems="center" gap="5px">
                    Página{'  '}<strong>{current} de {pageCount}</strong>
                </Box>
                <Button 
                    sx={{backgroundColor:'#6e4ea1', borderRadius:2,  "&:hover": { backgroundColor: '#808191'} }}
                    onClick={() => setCurrent((prev)=> prev+1 )}
                    disabled={(current === pageCount)}
                    >
                    <ArrowForwardIos />
                </Button>
                <Select
                    variant="outlined"
                    color='info'
                    displayEmpty
                    required
                    inputProps={{'aria-label': 'Without label'}}
                    defaultValue={10}
                    onChange={(e)=>{setPageSize(e.target.value ? Number(e.target.value) : 10)}}
                >
                    {[10, 20, 30, 40, 50].map((size) => (
                    <MenuItem key={size} value={size}>Ver {size}</MenuItem>
                    ))}
                </Select>
                </Box>
            )}
        </Box>
        <Stack flexDirection='row' padding={1} justifyContent='center' paddingY={6} gap={2}>
          <img src={ayuntamiento} alt="escudo" className="h-32"/>
          <img src={juskas} alt="juskas" className="h-32"/>
        </Stack>
    </div>
  )
}

export default ReporteSos