import { useState } from "react";
import { useForm } from "@refinedev/react-hook-form";
import Form from "components/common/Form";
import { FieldValues } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const CrearAnimal = () => {

  const navigate = useNavigate();

  const [animalImage, setAnimalImage] = useState({ name: '', url: ''})

  const { refineCore: {onFinish, formLoading}, setValue, register, handleSubmit } = useForm();

  const handleImageChange = (file: File) => {
    const reader = (readFile: File) => new Promise<string>
    ((resolve,reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => resolve(fileReader.result as string);
      fileReader.readAsDataURL(readFile);
    })
    reader(file).then((result:string) => setAnimalImage({name:file?.name, url:result }));
  }; 

  const onFinishHandler = async (data: FieldValues) => {
    if(!animalImage.name) return alert('Por favor selecciona una imagen')
    await onFinish({ ...data, photo: animalImage.url });
    navigate('/animales/registro-exitoso'); 
  }

  return (
    <Form
      type = "Create"
      register={register}
      onFinish={onFinish}
      formLoading={formLoading}
      handleSubmit={handleSubmit}
      handleImageChange={handleImageChange}
      onFinishHandler={onFinishHandler}
      animalImage={animalImage}
      setValue={setValue}
    />
  )
}

export default CrearAnimal