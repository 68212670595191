import { animales, ayuntamiento, inicioDos, inicioTres, inicioUno, moreliabrilla } from 'assets'
import { AiOutlineWhatsApp } from 'react-icons/ai'
import { BsArrowRightCircleFill } from 'react-icons/bs'
import { FiFacebook, FiPhone } from 'react-icons/fi'
import { MdLocationPin } from 'react-icons/md'

const Landing = () => {
  return (
    <div className='px-4'>
        <div className='flex justify-between py-4 items-center'>
            <img src={animales} alt="ayuntamiento" className='md:h-20 h-16'/>
            <img src={ayuntamiento} alt="morelia" className='h-20 md:h-24'/>
        </div> 
        <div className='mb-5'>
          <h1 className='text-3xl font-bold mb-1'>Bienvenido</h1>
          <h2 className='font-light text-lg'>Identidad y bienestar de tus mascotas</h2>
        </div>

        <div className='flex flex-col lg:flex-row gap-4'>
          <a href='/animales' className='bg-gradient-to-r from-[#3b82f6] to-[#9c27b0] rounded-2xl text-white flex pl-4'>
            <div className='flex flex-col gap-1 pt-4'>
              <h1 className='text-xl font-semibold'>Registrar Animales</h1>
              <h2 className='font-light text-sm'>Registro Público de Animales de Compañía</h2>
              <div className='pt-2 mb-4'>
                <BsArrowRightCircleFill className='text-slate-200 text-2xl'/>
              </div>
            </div>
            <img src={inicioUno} alt="inicio Uno" className='h-36'/>
          </a>
          <a href='/adopciones' className='bg-gradient-to-r from-[#3b82f6] to-[#9c27b0] rounded-2xl text-white flex pl-4'>
            <div className='flex flex-col gap-1 pt-4'>
              <h1 className='text-xl font-semibold'>Mejor Adopta</h1>
              <h2 className='font-light text-sm'>Sistema Integral de Adopción Municipal</h2>
              <div className='pt-2 mb-4'>
                <BsArrowRightCircleFill className='text-slate-200 text-2xl'/>
              </div>
            </div>
            <img src={inicioDos} alt="inicio Dos" className='h-36'/>
          </a>
          <a href='/tutoriales' className='bg-gradient-to-r from-[#3b82f6] to-[#9c27b0] rounded-2xl text-white flex pl-4'>
            <div className='flex flex-col gap-1 pt-4'>
              <h1 className='text-xl font-semibold'>Descubre como</h1>
              <h2 className='font-light text-sm'>Aprende cómo funciona y soluciona tus dudas</h2>
              <div className='pt-2 mb-4'>
                <BsArrowRightCircleFill className='text-slate-200 text-2xl'/>
              </div>
            </div>
            <img src={inicioTres} alt="inicio Tres" className='h-36'/>
          </a>
        </div>

        <img src={moreliabrilla} alt="logo morelia" className='h-24 ml-auto mr-auto my-5'/>

        <div className='flex flex-col items-center justify-center'>
          <h1 className='text-xl font-semibold my-4'>Contacto</h1>
          <div className="flex gap-3 text-2xl">
                <a href="https://www.facebook.com/CAAMorelia/?mibextid=LQQJ4d" rel="noreferrer" target='_blank' className="bg-[#9c27b0] text-white p-2 rounded-2xl hover:bg-dorado hover:duration-200"><FiFacebook /></a>
                <a href="tel:4433211392" className="bg-[#9c27b0] text-white p-2 rounded-2xl hover:bg-dorado hover:duration-200"><FiPhone /> </a>
                <a href="https://goo.gl/maps/AFgncmgLcX39iDHR6 "rel="noreferrer" target='_blank' className="bg-[#9c27b0] text-white p-2 rounded-2xl hover:bg-dorado hover:duration-200"><MdLocationPin /> </a>
                <a href="tel:4433214731" className="bg-[#9c27b0] text-white p-2 rounded-2xl hover:bg-dorado hover:duration-200"><FiPhone /> </a>
                <a href="https://wa.me/+527298094080 "rel="noreferrer" target='_blank' className="bg-[#9c27b0] text-white p-2 rounded-2xl hover:bg-dorado hover:duration-200"><AiOutlineWhatsApp /> </a>
          </div>
        </div>

        <div className='max-w-sm ml-auto mr-auto text-center'>
          <h3 className='text-xs mt-4'>Todos los derechos reservados al H. Ayuntamiento de Morelia © 2024</h3>
        </div>

    </div>
  )
}

export default Landing