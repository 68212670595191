import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { juskas, morelialg } from 'assets';
import Menu from '@mui/icons-material/Menu';

interface Props {
  window?: () => Window;
}

const drawerWidth = 240;

export const Navbar = (props: Props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

const navbardata = [ 
  { title:'FAQS', ref: '/faqs'},
  { title:'SOS', ref: '/sos'},
  // { title:'atención', ref: '/atencion'},
  { title:'registrarse', ref: '/register'} 
]

// Toggle Menu
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <a className='flex justify-center py-5' href='/'>
        <img src={morelialg} alt="morelia brilla" className="w-44" />
      </a>
      <Divider />
      <List>
        {navbardata.map((item,index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <a href={item.ref}>
                <ListItemText primary={item.title} className='uppercase'/>
              </a>
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem>
          <a href="/login" className="font-[400] bg-gradient-to-r from-black to-dorado text-white p-4 px-8 w-44 rounded-xl flex justify-center hover:scale-90 hover:duration-200"><button>Iniciar Sesión</button></a>
        </ListItem>
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box className='flex w-100%'>
      <AppBar component='nav' className='navbar mt-4 lg:px-12'>
        <Toolbar className='flex justify-between items-start'>
          <a href="/"><img src={juskas} alt="juskas" className="lg:w-44 md:w-44 w-28 lg:ml-0 ml-5" /></a> 

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
            className='icon'
          >
            <Menu/>
          </IconButton>

          <Box className='lg:flex md:flex lg:gap-20 md:gap-5 justify-between gap-10 items-center hidden icon'>
            {navbardata.map((item,index) => (
              <div key={index}>
                <a href={item.ref} className='capitalize font-[300] text-lg text-white hover:text-dorado hover:duration-200'>{item.title}</a>
              </div>
            ))}
            <a href="/login" className="font-[400] bg-gradient-to-r from-black to-dorado text-white p-4 px-8 w-44 rounded-xl flex justify-center hover:scale-90 hover:duration-200"><button>Iniciar Sesión</button></a>
          </Box>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none'},
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

    </Box>
  );
}