import { useState, useEffect } from 'react';
import { Add } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PetCard, CustomButton} from 'components';
import { FcGlobe, FcLike } from 'react-icons/fc';
import axios from 'axios';
import { GiDogHouse } from 'react-icons/gi';
import { HiCursorClick } from 'react-icons/hi';
import { motion } from 'framer-motion';
import { BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs';
import loadingAnimation from "../../assets/loading2.json";
import Lottie from 'lottie-react';

interface Animal {
  _id: string;
  procedencia: string;
  rfid: string;
  nfc: string;
  nombre: string;
  peso: string;
  sexo: string;
  birthdate: string;
  especie: string;
  raza: string;
  razaUnica: string;
  esterilizado: string;
  color: string;
  medio: string;
  sos: boolean;
  ultimaUbicacion: {
    latitud:number;
    longitud:number;
  };
  fechaReporte: string;
  telEmergencia: string;
  createdAt: string;
  photo: string;
}

interface User {
  name: string;
  lastname: string;
  curp: string;
  sexo: string;
  _id: string;
  birthdate: string;
  zipcode: string;
  allAnimales: Animal[]; 
}

const Animales = () => { 
  const [isExpanded, setIsExpanded] = useState(false);
  const [user, setUser] =  useState<User | null>(null);
  const [allAnimales, setAllAnimals] = useState<Animal[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedData = JSON.parse(storedUser);
      const id = parsedData._id;
      if (id) {
        axios.get(`https://juskas.onrender.com/api/v1/usuarios/${id}`)
          .then(response => {
            setUser(response.data);
            axios.get(`https://juskas.onrender.com/api/v1/animales/usuarios/${id}`)
              .then(response => {
                setAllAnimals(response.data);
              })
              .catch(error => {
                console.error('Error getting animals information:', error);
              });
          })
          .catch(error => {
            console.error('Error getting user information:', error);
          });
      } else {
        console.error('User ID not found in local storage');
      }
    } else {
      console.error('User data not found in local storage');
    }
    setTimeout(() => {
      setIsLoading(false);
  }, 2000);

  }, []);
  
  const navigate = useNavigate();

  const handleAnimationComplete = () => {
    if(!isExpanded){setIsExpanded(true)}
  }

  return (
    <div>
      { isLoading ? 
        <div className="flex items-center justify-center w-full">
            <Lottie animationData={loadingAnimation} className="max-w-sm"/>
        </div>  
      : ( 
        <div>
          {/* ------------------- Título / Crear Ejemplar ------------------- */}
                <div className='flex md:justify-between justify-center mt-4 mx-4 gap-4 flex-wrap items-start md:mb-2 mb-10'>
                  <div className='flex flex-col gap-1'>
                    <div className='flex gap-2 items-center'>
                      <FcGlobe className='text-3xl'/> 
                      <Typography fontSize={25} fontWeight={700} className='capitalize'>Hola {user?.name}</Typography>
                      <motion.button 
                        className='text-[#9c27b0] flex justify-center my-2 text-sm'
                        onClick={() => setIsExpanded(!isExpanded)}
                        whileHover={{ scale: 1 }}
                        whileTap={{ scale: 1 }}
                      >
                        {!isExpanded ? <BsCaretDownFill/> : <BsCaretUpFill /> }
                      </motion.button>
                    </div>
                    <div className='flex gap-2'>
                      <div>  
                        <Typography fontSize={16} fontWeight={100}>No. de Registro:</Typography>
                        <Typography fontSize={19} fontWeight={300}>{user?._id}</Typography>
                        <motion.div
                          initial={{ height: 0 }}
                          animate={{ height: isExpanded ? 'auto' : 0 }}
                          exit={{ height: 'auto'  }}
                          transition={{ duration: 0.5 }}
                        >
                          {isExpanded &&
                            <div>
                              <motion.p
                                key={isExpanded ? 'Cerrar' : 'Expandir'}
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.2, duration: 0.5 }}
                                className='text-base font-extralight mt-1'
                                onAnimationComplete={handleAnimationComplete}
                              >
                                Nacimiento: <span className=' font-light'>{user?.birthdate}</span> <br />
                                CURP: <span className='font-light'>{user?.curp}</span> <br />
                                Sexo: <span className='font-light'>{user?.sexo}</span> <br />
                                CP: <span className='font-light'>{user?.zipcode}</span>
                              </motion.p>
                            </div>
                          }
                        </motion.div>
                      </div>
                    </div>
                  </div>
                  <CustomButton 
                    title="Crear Animal"
                    handleClick={() => navigate('/animales/create')}
                    backgroundColor="#9c27b0"
                    color="#fcfcfc"
                    icon={<Add />}
                  />
                </div>
                <div className='flex gap-5 flex-wrap justify-center items-center'>
          {/* Número de Animales */}
                  <div className='ml-3 bg-gradient-to-r from-[#9c27b0] to-blue-500 w-36 h-36 rounded-full text-center text-white flex flex-col items-center justify-center shadow-2xl'>
                    <Typography fontSize={20} fontWeight={300}>Animales</Typography>
                    <Box className='flex justify-center items-center gap-2'>
                      <Typography fontSize={36} fontWeight={500} className='text-center'>{user?.allAnimales.length}</Typography>
                      <FcLike className='text-3xl'/>
                    </Box>
                  </div>
          {/* Instructivos Click */}
                  <div className='flex items-center gap-4'>
                    <div className='p-3 bg-[#9c27b0] rounded-full'>
                      <HiCursorClick className='text-2xl text-white'/>
                    </div>
                    <h1 className='text-[#9c27b0] font-semibold text-xl'>Click en cada animal</h1>
                  </div>
                </div>
          {/* ------------------- Cards ------------------- */}
                { allAnimales?.length > 0 ? (
                  <Box mt="20px" sx={{ display: "flex", flexWrap:"wrap", gap: 3, justifyContent:'center'}} className='bg-purple-100 rounded-2xl py-10 shadow-t2xl'>
                    {allAnimales.map((animal,index) => (
                      <PetCard 
                        key={index}
                        id={animal._id}
                        procedencia={animal.procedencia}
                        rfid={animal.rfid}
                        nfc={animal.nfc}
                        nombre={animal.nombre}
                        peso={animal.peso}
                        sexo={animal.sexo}
                        birthdate={animal.birthdate}
                        especie={animal.especie}
                        raza={animal.raza}
                        razaUnica={animal.razaUnica}
                        esterilizado={animal.esterilizado}
                        color={animal.color}
                        medio={animal.medio}
                        sos={animal.sos}
                        ultimaUbicacion={animal.ultimaUbicacion}
                        fechaReporte={animal.fechaReporte}
                        telEmergencia={animal.telEmergencia}
                        createdAt={animal.createdAt}
                        photo={animal.photo}
                      />
                    ))}
                  </Box> 
                  ) : (
                    <Stack className='flex flex-col items-center justify-center gap-3 mt-5 p-4 text-center'>
                      <GiDogHouse className='text-6xl text-moradoDark'/>
                      <Typography fontSize={20} fontWeight={300}>Aún no tienes Animales de Compañía Registrados</Typography>
                    </Stack>
                  )
                }
        </div>
      )}

    </div>
  )
}

export default Animales