import { dog, sosfour, sosjuskas, sosone, sosthree, sostwo, vidSos } from "assets"
import { AiFillCloseCircle, AiFillPhone, AiFillPlayCircle } from 'react-icons/ai';
import { Variants, motion } from "framer-motion"
import { slideLeft, slideRight } from "utils/motion";
import { Button, Fade, Modal } from "@mui/material";
import { useState } from "react";
import Sheet from 'react-modal-sheet';

const Left: Variants = slideLeft('left', 'spring', 0, 1)
const Right: Variants = slideRight('right', 'spring', 0, 1)

export const Sos = () => {
    const [open, setOpen] = useState(false);
    const [openHelp, setOpenHelp] = useState(false);

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <div className="flex h-fit items-center flex-wrap justify-between min-h-screen mb-10">
        <motion.div 
          className="flex flex-col items-center my-10 lg:mt-0"
          variants={Right}
          initial="hidden"
          whileInView="show"
        >
          <img src={dog} alt="cat" className="lg:max-w-[40rem]"/>
          <button className="flex items-center gap-2" onClick={handleOpen}>
            <AiFillPlayCircle className="text-[2.5rem] text-dorado"/>
            <p>Reproduce el video <br /> y ve como funciona</p>
          </button>
          <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
          >
            <Fade in={open}>
              <div className="flex justify-center items-center w-full h-full">
                <div className="bg-white w-full h-full md:max-w-lg max-w-xs md:max-h-[22rem] max-h-[15rem] rounded-2xl p-4">
                  <div className="flex justify-end">
                    <Button onClick={handleClose}><AiFillCloseCircle className="text-2xl text-moradoDark hover:text-morado" /> </Button>
                  </div>
                  <video src={vidSos} controls className="rounded-2xl"/>
                </div>
              </div>
            </Fade>
          </Modal>
        </motion.div>
        <motion.div
          variants={Left}
          initial="hidden"
          whileInView="show"
        >
          <div className="flex items-end lg:justify-start md:justify-start justify-center">
            <img src={sosjuskas} alt="sosjuskas" className="w-[11rem]"/>
            <h1 className="font-semibold lg:text-[2.5rem] md:text-[2.5rem] text-[2rem] -ml-5"> Juskas </h1>
          </div>
          <div>
            <p className="lg:max-w-[40rem] md:max-w-screen-md max-w-[20rem] my-10 leading-9 text-[1.2rem] font-light px-2 lg:text-left md:text-left text-center">
            ¿Sabes que los dueños pueden reencontrarse rápidamente con sus mascotas si se pierden? <br /><br />
            Con SOS Juskas ve los reportes de pérdida con fecha y última ubicación. Si perdiste a tu animal de compañía, puedes registrarte y realizar tu reporte.
            </p>
          </div>
          <div className="flex gap-4 flex-wrap lg:justify-start md:justify-start justify-center">
            <a href="/sos" className="font-[400] bg-gradient-to-r from-black to-dorado text-white p-4 px-8 w-44 rounded-xl flex justify-center hover:scale-90 hover:duration-200"><button>Ver Mapa</button></a>
            <button className="font-[400] border-2 border-dorado text-dorado p-4 px-8 w-44 rounded-xl flex justify-center hover:scale-90 hover:duration-200" onClick={() => setOpenHelp(true)}>Ayudar</button>
          </div>
        </motion.div>
        <Sheet isOpen={openHelp} onClose={() => setOpenHelp(false)}>
          <Sheet.Container>
          <Sheet.Header />
            <Sheet.Content>            
              <div className="flex justify-center flex-col items-center gap-2 p-4">
                
                <img src={sosjuskas} alt="sosjuskas" className="w-32"/>
                <h1 className="font-semibold text-2xl text-center">¿Qué hacer si encuentro un animal en la calle?</h1>

                <div className="flex flex-wrap justify-center gap-10">
                  <div className="flex gap-2 items-end flex-wrap justify-center">
                    <img src={sosone} alt="sos-one" className="w-44"/>
                    <div className="w-[20rem] mb-2">
                      <h2 className="font-medium text-xl">Escanea / Acércate</h2>
                      <p className="text-lg font-light">Acerca cuidadosamente tu celular al microchip, escanea el QR o acerca la placa inteligente a tu celular.</p>
                    </div>
                  </div>
                  <div className="flex gap-2 items-end flex-wrap justify-center">
                    <img src={sostwo} alt="sos-one" className="w-44"/>
                    <div className="w-[20rem] mb-2">
                      <h2 className="font-medium text-xl">Verifica</h2>
                      <p className="text-lg font-light">Verificasielanimalesdetenencia comunal o si cuenta con reporte de perdida o robo.</p>
                    </div>
                  </div>
                  <div className="flex gap-2 items-end flex-wrap justify-center">
                    <img src={sosthree} alt="sos-one" className="w-44"/>
                    <div className="w-[20rem] mb-2">
                      <h2 className="font-medium text-xl">Contacta</h2>
                      <p className="text-lg font-light">Si tiene reporte contacta con el dueño para apoyarlo con la recuperación de su animal de compañía.</p>
                    </div>
                  </div>
                  <div className="flex gap-2 items-end flex-wrap justify-center">
                    <img src={sosfour} alt="sos-one" className="w-44"/>
                    <div className="w-[20rem] mb-2">
                      <h2 className="font-medium text-xl">Pide apoyo</h2>
                      <p className="text-lg font-light mb-2">En caso de no contar con reporte y no ser de tenencia comunal comunícate aquí. </p>
                      <a href="tel:4433211392" className="text-white flex gap-2 items-center bg-moradoDark w-fit px-4 py-2 rounded-lg hover:bg-morado"><AiFillPhone /> Llamar</a>
                    </div>
                  </div>
                </div>
              </div>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>
      </div>
    )
  }