import { ExpandCircleDown } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material"
import { Footer } from "pages/home/footer"
import { Navbar } from "pages/home/navbar"
import { heroimg } from "assets"
import { Variants, motion } from "framer-motion"
import { slideLeft } from "utils/motion";
import { Helmet } from "react-helmet"

const Left: Variants = slideLeft('left', 'spring', 0, 1)

const Faqs = () => {
  return (
    <div className="lg:px-12 lg:py-8 px-8 py-8">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Preguntas Frecuentes</title>
            <meta property="og:title" content="Preguntas Frecuentes" />
            <meta property="og:description" content="Descubre todos los beneficios y cómo funciona el programa, aclara tus dudas y registra tus animales de compañía"/>
        </Helmet>
        <Navbar />
        <motion.img 
          src={heroimg} 
          alt="hero"  
          className="absolute top-0 right-0 lg:w-auto lg:h-[43rem] -z-20 w-full"
          variants={Left}
          initial="hidden"
          whileInView="show"
        />
        <div className="lg:mt-[12rem] mt-[10rem] mb-24 lg:px-32 px-2">
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandCircleDown />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>¿Dónde recoger mi kit?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Podrás recoger tu kit en puntos únicos del ayuntamiento como lo son: Centro de Atención Animal, Módulo del H. Ayuntamiento en Manantiales y Módulo del H. Ayuntamiento en Allende Centro.
                    </Typography>
                </AccordionDetails>
                </Accordion>

                <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandCircleDown />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography>¿Qué incluye mi kit?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    El kit incluye un collar, una cartilla de vacunación y una medalla para el collar de mascota con un código QR que te servirá para conectarlo directamente con la plataforma.
                    </Typography>
                </AccordionDetails>
                </Accordion>

                <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandCircleDown />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography>¿Cómo registrar a mi mascota?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    Para registrar a tu mascota, debes ingresar a la página web de Juskas (juskas.morelia.gob.mx), después de registrarte o iniciar sesión, da clic en "Registrar mascota" y sigue las instrucciones de llenado con los datos de tu mascota.
                    </Typography>
                </AccordionDetails>
                </Accordion>

                <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandCircleDown />}
                    aria-controls="panel4a-content"
                    id="panel4a-header"
                >
                    <Typography>¿Cómo me registro?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    Para registrarte en Juskas, debes ingresar a la página web (juskas.morelia.gob.mx) y dar clic en "Registrarse". Luego, deberás llenar el formulario de registro con tus datos personales.
                    </Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandCircleDown />}
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                >
                    <Typography>¿Cómo funciona el microchip para collares?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    El microchip para collares es un tipo de chip que se coloca en el collar de tu mascota y contiene información importante sobre tu perro o gato, como su nombre, dirección y datos de contacto. Puedes escanear el chip con tu teléfono móvil y vincularlo con la plataforma Juskas para tener acceso a los datos de tu mascota.
                    </Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandCircleDown />}
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                >
                    <Typography>¿Cómo poner SOS?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    Si pierdes a tu mascota, puedes activar el modo SOS en la plataforma Juskas, lo que hará que tu perro o gato aparezca en un mapa de perros perdidos. Para activar el modo SOS, entrar al perfil de tu mascota y seleccionar la opción "Activar modo SOS".
                    </Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandCircleDown />}
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                >
                    <Typography>¿Cómo funciona el microchip subcutáneo?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    El microchip subcutáneo es un chip pequeño que se implanta debajo de la piel de tu mascota y que contiene información importante sobre tu perro o gato, como su nombre, dirección y datos de contacto. Los chips subcutáneos son seguros y no perjudiciales para la salud de tu mascota.
                    </Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandCircleDown />}
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                >
                    <Typography>¿El microchip subcutáneo es perjudicial para la salud de mi mascota?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    No, el microchip subcutáneo es seguro y no perjudica la salud de tu mascota. El procedimiento de implantación es rápido y no causa dolor significativo. El chip es del tamaño de un grano de arroz y está diseñado para durar toda la vida de tu mascota.
                    </Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandCircleDown />}
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                >
                    <Typography>¿El microchip para collares es perjudicial para la salud de mi mascota?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    No, el microchip para collares es un dispositivo seguro y no es perjudicial para la salud de tu mascota.
                    </Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandCircleDown />}
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                >
                    <Typography>¿Qué hacer si encuentro un animal perdido?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    Si encuentras un animal perdido, puedes escanear su microchip o QR y verificar si está registrado en la plataforma. Si está registrado, puedes contactar al dueño a través de la información de contacto proporcionada en el perfil de la mascota.
                    </Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandCircleDown />}
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                >
                    <Typography>¿Qué hacer si pierdo mi microchip?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    Si pierdes el microchip para collares, puedes solicitar uno en los modulos (sujeto a disponibilidad). 
                    </Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandCircleDown />}
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                >
                    <Typography>¿El registro tiene un costo?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    No, el registro es gratuito. Sin embargo, se requiere un microchip para collares o subcutáneo para registrar a tu mascota.
                    </Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandCircleDown />}
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                >
                    <Typography>¿Cuáles son los beneficios?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    Los beneficios de registrarte en Juskas incluyen tener un registro completo de tu mascota, acceso a un mapa de perros perdidos y la capacidad de activar el modo SOS en caso de que tu mascota se pierda. Además, al registrarte, recibirás un kit gratuito que incluye un collar, una cartilla de vacunación y una medalla para el collar de tu mascota con un código QR que se vincula con el perfil de tu mascota en la plataforma.
                    </Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandCircleDown />}
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                >
                    <Typography>¿El registro en la plataforma es obligatorio?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    No, el registro en la plataforma no es obligatiorio, pero es muy útil y tiene beneficios para los dueños de mascotas, así como para el gobierno local para mantener un registro completo de mascotas y animales en el área.
                    </Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandCircleDown />}
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                >
                    <Typography>¿Puedo modificar la información de mi mascota en la plataforma?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    No, para modificaciones sobre la información de tu mascota contactar con el Centro de Atención Animal.
                    </Typography>
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandCircleDown />}
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                >
                    <Typography>¿Mis datos están protegidos en la plataforma?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                    Sí, tus datos están protegidos de acuerdo con el Aviso de Privacidad de Transparencia de Gobierno Municipal.
                    </Typography>
                </AccordionDetails>
                </Accordion>
        </div>
        <Footer />
    </div>
  )
}

export default Faqs