import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Box } from "@mui/system";
import { Button, Stack, Typography } from "@mui/material";
import Sheet from 'react-modal-sheet';
import QRCode from "react-qr-code";
import { AnimalCardPublicProps } from "interfaces/animal";
import { BsGenderFemale, BsGenderMale, BsHouseHeart, BsHouses, BsHouseSlash, BsQrCodeScan } from "react-icons/bs";
import { ayuntamiento, juskas, whiteayuntamiento, whitejuskas, whitemoreliabrilla, whitemorelialg } from "assets";
import { LocalHospital, Phone, Sos } from "@mui/icons-material";
import { TbCat, TbDog } from "react-icons/tb";
import Map from "components/common/Map";
import { Helmet } from 'react-helmet';
import { FacebookIcon, FacebookShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import loadingAnimation from "../../assets/loading2.json";
import loadingError from "../../assets/404.json";
import Lottie from 'lottie-react';
import { CustomButton } from "components";
import { FaHome } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import { AiOutlineWhatsApp } from "react-icons/ai";

const AnimalesPublico = () => {
  const { id } = useParams();
  const [animales, setAnimales]=useState<AnimalCardPublicProps | null >(null)
  const [isOpen, setOpen] = useState(false);
  const [isOpenReporte, setOpenReporte] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    // Fetch the specific post by ID from Express server's API endpoint
    axios.get(`https://juskas.onrender.com/api/v1/animales/a/${id}`)
      .then(res => {
        setAnimales(res.data); // Update state with retrieved post
        setIsLoading(false);
      })
      .catch(
        err => { 
        console.error(err)
        setHasError(true)
        setIsLoading(false)
      });
  }, [id]);

  if (hasError) {
    return (
      <div>
        <div className="flex flex-col items-center justify-center w-full min-h-screen">
          <Lottie animationData={loadingError} className="max-w-sm"/>
          <h1 className="text-2xl font-semibold mb-5">La página que estás buscando no existe</h1>
          <CustomButton title="Regresar al inicio" handleClick={() => navigate('/')} backgroundColor="#9c27b0" color="#fcfcfc" icon={<FaHome />} />
        </div> 
      </div> 
    )
  }

  if (isLoading) {
    return   (
      <div>
        <div className="flex flex-col items-center justify-center w-full min-h-screen">
          <Lottie animationData={loadingAnimation} className="max-w-sm"/>
        </div>  
      </div>
    )
  }

  const shareUrl =  `https://juskas.morelia.gob.mx/registro/${id}`

  return (
    <Box
      padding={{lg:"50px", md: "20px", sm: "0", xs: "0"}}
      minHeight="100vh"
      className='bg-forms bg-contain bg-no-repeat flex items-start justify-center lg:gap-10'
      >
      <Helmet>
          <meta charSet="utf-8" />
          <title>{animales?.nombre}</title>
          <meta property="og:title" content={`${animales?.nombre}`} />
          <meta property="og:description" content={`Registro Público Municipal de Animales de Compañia`} />
          <meta property="og:image" content={animales?.photo} />
      </Helmet>
{/* Pet Card */}
      <Box className='flex'>
        <Box position='absolute' top='0' left='0' bgcolor="#6e4ea1" py='10px' px='20px' borderRadius='0 15px 15px 15px'>
          <img src={whitemorelialg} alt="morelia brilla" height='15px'/>
        </Box>  
        { animales?.sos?
          <Box position='absolute' top='0' right='0' bgcolor="red" py='5px' px='10px' borderRadius='15px 0 15px 15px '>
            <Sos sx={{fontSize:"50px", color:'#f0f0f0'}}/>
          </Box> : <></> 
        }
        <div className='md:max-w-sm bg-white shadow-2xl rounded-b-2xl md:rounded-2xl pb-10' id='proyectojuskas' >
          <img 
            src={animales?.photo}
            alt={animales?.nombre} 
            loading="lazy"
            height={450}
            style={{ objectFit: "cover"}} 
            className="md:max-w-sm rounded-b-2xl md:rounded-2xl w-full"
          />
          {animales?.sos?
          <Stack marginTop='10px' width='100%' paddingX={2}>
            <Button onClick={() => setOpenReporte(true)} sx={{textAlign:'center', borderRadius:'20px', color:'red', border:1, borderColor:"red", fontWeight:600, fontSize:'20px', gap:'5px', paddingY:'10px', paddingX:'20px'}}>
              <Typography>Ver Reporte </Typography>
              <Sos sx={{fontSize:"30px"}}/>
            </Button>
          </Stack> : <></>
          }
            <div className='flex w-full justify-between my-[1rem] px-4'>
                <Stack direction="column" gap={0.5} alignItems="center" py={2} borderRadius='20px' bgcolor="#f0f0f0" height="fit-content" minWidth={110}>
                  <Typography fontSize={15} textTransform="capitalize">Nacimiento</Typography>   
                  <Typography fontSize={15} fontWeight={600} textTransform="capitalize">{animales?.birthdate}</Typography>   
                </Stack>
                <Stack direction="column" gap={0.5} alignItems="center" py={2} borderRadius='20px' bgcolor="#f0f0f0" height="fit-content" minWidth={100}>
                  <Typography fontSize={15} textTransform="capitalize">Peso</Typography>   
                  <Typography fontSize={15} fontWeight={600} textTransform="capitalize">{animales?.peso} kg</Typography>   
                </Stack>
                <Stack direction="column" gap={0.5} alignItems="center" py={2} borderRadius='20px' bgcolor="#f0f0f0" height="fit-content" minWidth={110}>
                  <Typography fontSize={15}  textTransform="capitalize">Sexo</Typography>   
                  <Typography fontSize={15} fontWeight={600} textTransform="capitalize" display='flex' gap={1} alignItems='center'>{animales?.sexo === 'masculino' ? <BsGenderMale/>: <BsGenderFemale/>} {animales?.sexo === 'masculino' ? 'macho': 'hembra'}</Typography>    
                </Stack>
            </div>
            <Stack direction="row" alignItems="end" justifyContent={{lg:"space-between", md:"space-between", sm:"space-between", xs: "space-between"}} flexWrap='wrap' paddingX='30px' paddingTop='10px'>
              <Stack direction="column" gap={1.5}>
                <Stack direction="row" gap={1} alignItems='center'>
                  {animales?.especie === 'perro'? <TbDog fontSize={42} color='#6e4ea1'/> : <TbCat fontSize={42} color='#6e4ea1'/>}
                  <Typography fontSize={32} fontWeight={700}> {animales?.nombre}</Typography>
                </Stack>
                <Stack direction="row" gap={1} alignItems='center'>
                  {animales?.procedencia === 'compañia'? <BsHouseHeart fontSize={26}/> : animales?.procedencia === 'comunal' ? <BsHouses fontSize={24}/> : <BsHouseSlash fontSize={24}/>}
                  <Typography fontSize={20} fontWeight={700} textTransform='capitalize'>
                    {animales?.procedencia === 'compañia'? 'Animal de Compañía' : animales?.procedencia === 'comunal' ? 'Tenencia Comunal' : 'Calle'}
                  </Typography>
                </Stack>
                <Typography fontSize={20} fontWeight={700} textTransform='capitalize'>{animales?.raza === 'raza' ? animales?.razaUnica : animales?.raza}</Typography>
                { animales?.medio !== undefined &&
                  <Typography fontSize={20} textTransform="capitalize">Procedencia: <b>{animales.medio}</b></Typography>
                }
                <Typography fontSize={20} textTransform="capitalize">Color: <b>{animales?.color}</b></Typography>
                <Stack direction="row" justifyContent='center' maxWidth='180px' alignItems="center" px={1.5} py={0.5} borderRadius={1} bgcolor={`${animales?.esterilizado === "si" ? '#90ee90': '#ffc9bb'}`}  height="fit-content">
                  <LocalHospital
                  sx={{ fontSize: 18, color:`${animales?.esterilizado === "si" ? 'green': 'red'}`}}
                  />
                  <Typography color={`${animales?.esterilizado === "si" ? 'green': 'red'}`} fontSize={18} fontWeight={300}>{animales?.esterilizado === 'si' ? 'Esterilizado' : 'No esterilizado'}</Typography>
                </Stack>
              </Stack>
              <div className="flex flex-col items-center justify-center gap-4 mt-10">
                <div className="flex gap-5 items-center">
                  <img src={ayuntamiento} alt="ayuntamiento" className="w-20"/>
                  <img src={juskas} alt="juskas" className="w-28"/>
                </div>
              </div>
          </Stack>
        </div>
      </Box>
{/* QR personalizado */}
      <Box flex={1} flexDirection="column" gap="20px" className='max-w-sm lg:flex hidden'>
        <Box className='w-100 flex flex-col p-4 justify-center items-center gap-1 rounded-2xl shadow-2xl bg-gradient-to-r from-purple-800 to-[#E100FF]'>
          <div className="w-full py-4 px-12 gap-2 flex text-white items-center">
            {animales?.especie === 'perro'? <TbDog fontSize={42}/> : <TbCat fontSize={42}/>}
            <h1 className="text-white font-bold text-2xl text-center">{animales?.nombre}</h1>
          </div>
          <div className="bg-white p-6 rounded-2xl">
            <QRCode value={`https://juskas.morelia.gob.mx/registro/${id}`}/>
          </div>
          <h1 className="text-white font-light uppercase text-sm text-center">{id}</h1>
          <div className="flex justify-between w-full px-12 py-4 items-center">
            <img src={whitejuskas} alt='juskas' className="w-14"/>
            <img src={whiteayuntamiento} alt="ayuntamiento" className="w-12" />
            <img src={whitemoreliabrilla} alt='juskas' className="w-14"/>
          </div>
        </Box>
      </Box>
{/* Modal QR */}
      <Stack>
        <Button onClick={() => setOpen(true)} sx={{position:"fixed", bottom:0, right: 0, bgcolor:'#6e4ea1', color:'#fcfcfc', padding:'18px', mb:'25px', mr:'25px', display:{xs: 'flex', sm: 'flex', md:'none', lg:'none'} }}><BsQrCodeScan fontSize='40px'/></Button>
        <Sheet isOpen={isOpen} onClose={() => setOpen(false)}>
          <Sheet.Container>
          <Sheet.Header />
            <Sheet.Content>
              <Box className='max-w-sm ml-auto mr-auto px-4'>
                <Box className='w-100 flex flex-col p-4 justify-center items-center gap-1 rounded-2xl shadow-2xl bg-gradient-to-r from-purple-800 to-[#E100FF]'>
                  <div className="bg-white p-6 rounded-2xl">
                    <QRCode value={`https://juskas.morelia.gob.mx/registro/${id}`}/>
                  </div>
                  <h1 className="text-white font-light uppercase text-sm text-center">{id}</h1>
                </Box>
              </Box>
              <div className="flex flex-col gap-4 p-4 justify-center items-center">
                <h1 className="font-bold text-2xl">Comparte mi Perfil</h1>
                <div className="flex justify-center gap-6 p-1">
                  <FacebookShareButton url={shareUrl} quote={`¡Hola mi nombre es ${animales?.nombre}, soy un ${animales?.especie} y tengo mi Registro Único en el Municipio de Morelia.`}>
                    <FacebookIcon className="rounded-full"/>
                  </FacebookShareButton>              
                  <WhatsappShareButton url={shareUrl} title={`¡Hola mi nombre es ${animales?.nombre}, soy un ${animales?.especie} y tengo mi Registro Único en el Municipio de Morelia.`}>
                    <WhatsappIcon className="rounded-full"/>
                  </WhatsappShareButton>
                </div>
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h1 className='text-xl font-semibold mt-4 mb-2'>Contacto</h1>
                <h2 className='text-lg font-light mb-4'>Centro de Atención Animal</h2>
                <div className="flex gap-3 text-2xl">
                      <a href="tel:4433211392" className="bg-[#9c27b0] text-white p-2 rounded-2xl hover:bg-dorado hover:duration-200"><FiPhone /> </a>
                      <a href="tel:4433214731" className="bg-[#9c27b0] text-white p-2 rounded-2xl hover:bg-dorado hover:duration-200"><FiPhone /> </a>
                      <a href="https://wa.me/+527298094080 "rel="noreferrer" target='_blank' className="bg-[#9c27b0] text-white p-2 rounded-2xl hover:bg-dorado hover:duration-200"><AiOutlineWhatsApp /> </a>
                </div>
              </div>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>
      </Stack>
{/* Modal SOS */}    
      <Sheet isOpen={isOpenReporte} onClose={() => setOpenReporte(false)}>
          <Sheet.Container>
          <Sheet.Header />
            <Sheet.Content>
              <Stack
                width="100%"
                p={4}
                direction="column"
                justifyContent="center"
                alignItems="center"
                gap={1}
              >
                <Box bgcolor="red" py='5px' px='15px' borderRadius='15px 0 15px 15px' flexDirection='row' display='flex' alignItems='center' gap={1}>
                  <Typography color='#f0f0f0' fontWeight={800} fontSize='27px' textTransform='uppercase'>Reporte</Typography>
                  <Sos sx={{fontSize:"50px", color:'#f0f0f0'}}/>
                </Box>

                <Stack direction='row' justifyContent='space-evenly' width='100%' alignItems='center'>
                  <Stack gap={1}>
                    <Typography sx={{textAlign:'center'}}>Fecha de reporte: <br /> <b style={{letterSpacing:1, fontSize:'22px'}}>{animales?.fechaReporte}</b></Typography>
                  </Stack>
                </Stack>

                <Typography sx={{textAlign:'center'}}>Teléfono de emergencia:</Typography>
                <Stack direction='row' gap={0.5} alignItems='center' fontSize='22px' letterSpacing={1}>
                  <Phone sx={{fontSize:'24px', bgcolor:'#6e4ea1', color:'#fcfcfc', padding: '3px', borderRadius:'50%'}}/>
                  <a href={`tel:${animales?.telEmergencia}`}>{animales?.telEmergencia}</a>
                </Stack>
                <Typography sx={{textAlign:'center'}}>Ultima ubicación:</Typography>
                <Stack minWidth='300px' maxWidth='450px'>
                  {animales?.ultimaUbicacion &&
                  <Map lat={animales.ultimaUbicacion.latitud} lng={animales?.ultimaUbicacion.longitud}/>
                  }
                </Stack>
                <div className="flex flex-col gap-4 p-4 justify-center items-center">
                <h1 className="font-bold text-2xl">Comparte mi SOS</h1>
                <div className="flex justify-center gap-6 p-1">
                  <FacebookShareButton url={shareUrl} quote={`¡Hola mi nombre es ${animales?.nombre}, soy un ${animales?.especie}, estoy perdido desde el ${animales?.fechaReporte}, mi última ubicación la puedes ver en el siguiente link, por favor lláma al ${animales?.telEmergencia} para que me pueda reencontrar con mi dueño`}>
                    <FacebookIcon className="rounded-full"/>
                  </FacebookShareButton>              
                  <WhatsappShareButton url={shareUrl} title={`¡Hola mi nombre es ${animales?.nombre}, soy un ${animales?.especie}, estoy perdido desde el ${animales?.fechaReporte}, mi última ubicación la puedes ver en el siguiente link, por favor lláma al ${animales?.telEmergencia} para que me pueda reencontrar con mi dueño`}>
                    <WhatsappIcon className="rounded-full"/>
                  </WhatsappShareButton>
                </div>
              </div>
              </Stack> 
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
      </Sheet>
    </Box>
  )
}

export default AnimalesPublico