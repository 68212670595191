import { useForm } from "@refinedev/react-hook-form";
import { FieldValues } from "react-hook-form";
import FormEdit from "components/common/FormEdit";
import { useEffect, useState } from "react";

const EditarAnimal = () => {
  
  const { refineCore: {onFinish, formLoading}, setValue, register, handleSubmit, watch } = useForm();


  const [animalImage, setAnimalImage] = useState({ name: '', url: ''})

  const handleImageChange = (file: File) => {
    const reader = (readFile: File) => new Promise<string>
    ((resolve,reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => resolve(fileReader.result as string);
      fileReader.readAsDataURL(readFile);
    })
    reader(file).then((result:string) => setAnimalImage({name:file?.name, url:result }));
  }; 

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedData = JSON.parse(storedUser);
      const email = parsedData.email;
      setValue('email', email);
    }
  }, [setValue]);

  const onFinishHandler = async (data: FieldValues) => {
    await onFinish({ ...data, photo: animalImage.url });
  }

  return (
    <FormEdit
      type="Edit"
      register={register}
      onFinish={onFinish}
      formLoading={formLoading}
      handleSubmit={handleSubmit}
      handleImageChange={handleImageChange}
      onFinishHandler={onFinishHandler}
      setValue={setValue}
      animalImage={animalImage}
      watch={watch}
    />
  )
}

export default EditarAnimal