import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Box, Button, CardContent, Container, TextField, Typography, Link as MuiLink, InputAdornment, IconButton } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import { layoutStyles, titleStyles } from "./styles";
import { morelialg } from "assets";
import { authProvider } from '../../authProvider'; 
import loadingAnimation from "../../assets/loading.json";
import Lottie from 'lottie-react';
import { Helmet } from "react-helmet";
import { RiLockPasswordLine } from "react-icons/ri";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { MdOutlineMailOutline } from "react-icons/md";

interface LoginFormValues {
  email: string;
  password: string;
}

export const Login = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValues>();

  const onSubmit = async (data: LoginFormValues) => {
    try {
        setLoading(true)
        const response = await axios.post("https://juskas.onrender.com/api/v1/usuarios/login", data);
        const accessToken = response.data.accessToken;
        localStorage.setItem("accessToken", accessToken);
        console.log("Inicio de sesión exitoso");
        // Llamar al método login del auth provider con los datos del usuario
        await authProvider.login({ email: data.email, password: data.password });
        // Redirigir a la página de inicio después del inicio de sesión exitoso
        window.location.href = "/";
    } catch (error) {
      setError(true);
      setLoading(false)
    }
  };

  return (
    <div className="bg-forms bg-contain bg-no-repeat">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Iniciar Sesión</title>
          <meta property="og:title" content="Iniciar Sesión" />
          <meta property="og:description" content="Accede al Registro Público Municipal de Animales de Compañia, administra tus animales, activa SOS Juskas y conecta tu microchip"/>
        </Helmet>
        <Container maxWidth="sm">
            <Box component="div" style={layoutStyles} className='flex items-center w-full justify-center min-h-screen'>
                <Container
                    component="main"
                    className='shadow-xl rounded-xl'
                    maxWidth="xs"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <CardContent sx={{ p: "32px", "&:last-child": { pb: "32px" } }} component="form" onSubmit={handleSubmit(onSubmit)} >
                        <a href="/home" className="flex justify-center mb-5"><img src={morelialg} alt="morelia-imagotipo"  height='10px' width="200px" style={{paddingTop: "20px"}}/></a>
                        <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                            style={titleStyles}
                            color="primary"
                            fontWeight={700}
                        >
                        Iniciar Sesión
                        </Typography>
                        {loading ? 
                        <div className="w-full flex items-center justify-center">
                            <Lottie animationData={loadingAnimation}/>
                        </div>  
                        :
                        <div>
                            <TextField
                                {...register("email", {
                                    required: true,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Email inválido",
                                    },
                                })}
                                id="email"
                                margin="normal"
                                fullWidth
                                label="Correo Electrónico"
                                error={!!errors.email || error}
                                inputProps={{ maxLength: 60 }}
                                InputProps={{
                                    startAdornment: <MdOutlineMailOutline className="mx-2 text-lg"/>
                                }}
                                helperText={
                                    errors["email"] ? errors["email"].message : ""
                                }
                                name="email"
                                sx={{
                                    mt: 0,
                                }}
                            />
        {/* Contraseña ----------------------------------*/} 
                            <TextField
                                {...register("password", {
                                    required: true,
                                })}
                                id="password"
                                margin="normal"
                                fullWidth
                                name="password"
                                label="Contraseña"
                                helperText={ error && "Usuario o contraseña incorrectos" }
                                error={error}
                                type={showPassword ? 'text' : 'password'}
                                placeholder="●●●●●●●●"
                                InputProps={{
                                    startAdornment: <RiLockPasswordLine className="mx-2 text-lg"/>,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    mb: 0,
                                }}
                            />
                            <MuiLink
                                variant="body2"
                                color="primary"
                                fontSize="12px"
                                component={RouterLink}
                                underline="none"
                                to="/forgot-password"
                            >
                                ¿Olvidaste tu contraseña?
                            </MuiLink>
                {/* Botón Enviar */}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: "24px",
                                }}
                            >
                                {loading ? 'Iniciando...' : 'Iniciar sesión'}
                            </Button>
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="center"
                                sx={{
                                    mt: "24px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    component="span"
                                    fontSize="12px"
                                >
                                    ¿No tienes una cuenta?
                                </Typography>
                                <MuiLink
                                    component={RouterLink}
                                    ml="4px"
                                    variant="body2"
                                    color="primary"
                                    underline="none"
                                    to="/register"
                                    fontSize="12px"
                                    fontWeight="bold"
                                >
                                    Regístrate
                                </MuiLink>
                            </Box>
                        </div>
                        }
                    </CardContent>
                </Container>
            </Box>
        </Container>
    </div>
  );
};
