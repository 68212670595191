import { ayuntamiento, heroimg, moreliabrilla } from "assets"
import { Variants, motion } from "framer-motion"
import { slideLeft, slideRight } from "utils/motion";

const Left: Variants = slideLeft('left', 'spring', 0, 1)
const Right: Variants = slideRight('right', 'spring', 0, 1)

export const Hero = () => {
  
    return (
      <div id='inicio'>
        <motion.img 
          src={heroimg} 
          alt="hero"  
          className="absolute top-0 right-0 lg:w-auto lg:h-[43rem] -z-20 w-full"
          variants={Left}
          initial="hidden"
          whileInView="show"
        />

        <motion.div 
          className="md:mt-[37rem] mt-[20rem] lg:mt-[17rem] w-full" 
          variants={Right}
          initial="hidden"
          whileInView="show"
        >
          <h1 className="lg:text-[2.5rem] md:text-[2.5rem] text-[1.44rem] font-semibold">Registro Público Municipal <br /> de Animales de Compañía</h1>
          <h2 className="lg:text-[1.4rem] md:text-[1.5rem] text-[1.12rem] leading-8 mt-5 font-light">Inicia gratuitamente y obten un certificado único <br className="hidden lg:block" /> expedido por el H. Ayuntamiento de Morelia</h2>
        
          <div className="flex gap-4 mt-10">
            <a href="/register" className="font-[400] bg-gradient-to-r from-black to-dorado text-white p-4 px-8 w-44 rounded-xl flex justify-center hover:scale-90 hover:duration-200"><button>Regístrate</button></a>
            <a href="/login" className="font-[500] border-[3px] border-dorado text-dorado p-4 px-8 w-44 rounded-xl flex justify-center hover:scale-90 hover:duration-200"><button>Iniciar </button></a>
          </div>

          <div className="flex items-center justify-center gap-6 lg:mt-5 mt-14">
            <img src={ayuntamiento} alt="morelia" className="lg:h-24 md:h-24 h-20"/>
            <img src={moreliabrilla} alt="morelia" className="lg:h-28 md:h-28 h-20"/>
          </div>
        </motion.div>
      </div>
    )
  }