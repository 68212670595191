import { GpsFixedSharp, HealthAndSafety, LocalHospital } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Typography,Card, CardMedia, CardContent, Stack } from "@mui/material";

import { TbCat, TbDog} from 'react-icons/tb'
import { MdSos} from 'react-icons/md'

import { AnimalCardProps } from "interfaces/animal";

const PetCardPublic = ({ id, nfc, rfid, procedencia, nombre, especie, esterilizado, sos, photo}: AnimalCardProps) => {
  return (
    <Card
      component={Link}
      to={`/registro/${id}`}
      sx={{
        maxWidth: '330px',
        paddingX: '15px',
        paddingTop: '15px',
        '&:hover' : {
          boxShadow: '0 22px 45px 22px rgba(176,176,176,0.1)'
        },
        cursor: 'pointer',
        borderRadius: '30px'
      }}
      className="card-bg"
      elevation={0}
    >
      <CardMedia component="img" image={photo} alt="card image" sx={{ borderRadius: '30px'}} className="card-style"/>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems:'start', gap: '10px', paddingX: '5px'}} >
{/* Nombre */}
        <Stack direction='row' display='flex' width='100%' justifyContent='space-between' alignItems='center'>
          <Stack direction="column" gap={0}>
            <Stack direction='row' alignItems='center' gap='5px'>
              { sos ? <MdSos style={{fontSize:32, color:'red'}}/>: ''} 
              <Typography fontSize={16} fontWeight={600} color="purple">{nombre}</Typography>
            </Stack>
            <Typography fontSize={10} fontWeight={100} color="purple">ID:{id}</Typography>
          </Stack>
  {/* Microchips */}
          <Stack direction="row" gap={1}>
            { nfc !== undefined && nfc !== ''?
              <Stack direction="column" px={1} py={0.5} borderRadius={1} alignItems='center' color="#808191" bgcolor="#f0f0f0" gap='2px' fontWeight={600}>
                <HealthAndSafety sx={{fontSize:"14px", color:"#6e4ea1"}}/>
                <Typography fontSize={10} fontWeight={800}> NFC </Typography>
              </Stack>
              : <></> }
            { rfid !== undefined && rfid !== ''?
              <Stack direction="column" px={1} py={0.5} borderRadius={1} alignItems='center' color="#808191" bgcolor="#f0f0f0" gap='2px' fontWeight={600}>
                <HealthAndSafety sx={{fontSize:"14px", color:"#6e4ea1"}}/>
                <Typography fontSize={10} fontWeight={800}> RFID </Typography>
              </Stack>
            : <></> }
          </Stack>
        </Stack>
{/* Procedencia, Especie */}
        <Stack direction="row" gap={1} width='100%' justifyContent='space-between' fontWeight={300} color="#808191">
          <Stack direction="row" gap={0.5} alignItems="center" px={1} py={0.5} borderRadius={1} bgcolor="lavender" height="fit-content">
            <GpsFixedSharp 
              sx={{ fontSize: 16, color:'purple'}}
              />
            <Typography fontSize={12} textTransform="capitalize" color={'purple'}>{procedencia}</Typography>
          </Stack>
          <Stack direction="row" gap={0.5} alignItems="center" px={1} py={0.5} borderRadius={1} bgcolor="lavender" height="fit-content">
            {especie === 'perro' ? <TbDog  style={{ fontSize: 16, color: 'purple'}}/> : <TbCat  style={{ fontSize: 16}}/>}
            <Typography fontSize={12} textTransform="capitalize" color={'purple'} >{especie}</Typography>
          </Stack>
          {/* Esterilizado */}
          <Stack direction="row" gap={0.5} alignItems="center" px={1} py={0.5} borderRadius={1} bgcolor={`${esterilizado === "si" ? '#90ee90': '#ffc9bb'}`} height="fit-content">
              <LocalHospital
              sx={{ fontSize: 16, color:`${esterilizado === "si" ? 'green': 'red'}`}}
              />
              <Typography color={`${esterilizado === "si" ? 'green': 'red'}`} fontSize={11} >{esterilizado === 'si' ? 'Esterilizado' : 'No esterilizado'}</Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default PetCardPublic