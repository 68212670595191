type SlideDirection = 'left' | 'right' | 'up' | 'down';
type TransitionType = 'tween' | 'spring' | 'keyframes' | 'just' | undefined;

type SlideInVariants = {
  hidden: { x: string; y: string; opacity: number };
  show: {
    opacity: number,
    x: number;
    y: number;
    transition: {
      type: TransitionType;
      delay?: number;
      duration?: number;
      ease?: string;
    };
  };
  [key: string]: any;
};

export const slideLeft = (direction: SlideDirection, type: TransitionType = 'tween', delay = 0, duration = 2): SlideInVariants => ({
  hidden: {
    x: "-25vw",
    opacity: 0,
    y: "0",
  },
  show: {
    opacity: 1,
    x: 0,
    y: 0,
    transition: {
      type,
      delay,
      duration,
      ease: 'easeOut',
    },
  },
});


export const slideRight = (direction: SlideDirection, type: TransitionType = 'tween', delay = 0, duration = 2): SlideInVariants => ({
  hidden: {
    x: "-10vw",
    opacity: 0,
    y: "10vh",
  },
  show: {
    opacity: 1,
    x: 0,
    y: 0,
    transition: {
      type,
      delay,
      duration,
      ease: 'easeOut',
    },
  },
});

export const slideTop = (direction: SlideDirection, type: TransitionType = 'tween', delay = 0, duration = 2): SlideInVariants => ({
  hidden: {
    x: "0",
    opacity: 0,
    y: "25vh",
  },
  show: {
    opacity: 1,
    x: 0,
    y: 0,
    transition: {
      type,
      delay,
      duration,
      ease: 'easeOut',
    },
  },
});

export const slideDown = (direction: SlideDirection, type: TransitionType = 'tween', delay = 0, duration = 2): SlideInVariants => ({
  hidden: {
    x: "0",
    opacity: 0,
    y: "-25vh",
  },
  show: {
    opacity: 1,
    x: 0,
    y: 0,
    transition: {
      type,
      delay,
      duration,
      ease: 'easeOut',
    },
  },
});