import { Stats } from "./stats"
import { Hero } from "./hero"
import { Info } from "./info"
import { Navbar } from "./navbar"
import { How } from "./how"
import { Sos } from "./sos"
import { Footer } from "./footer"
import ScrollToTop from "react-scroll-up"
import { TbSquareRoundedArrowUpFilled } from "react-icons/tb"
import { Helmet } from "react-helmet"


export const Home = () => {
  return (
    <div className="lg:px-12 lg:py-8 px-8 py-8">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Inicio</title>
        <meta property="og:title" content="Inicio" />
        <meta property="og:description" content="Registro Público Municipal de Animales de Compañía"/>
      </Helmet>
      <div className="min-h-screen lg:mb-0 md:mb-0 mb-10">
        <Navbar />
        <Hero />
      </div>
      <Stats />
      <Info />
      <How />
      <Sos />
      <Footer />
      <ScrollToTop showUnder={160}>
        <TbSquareRoundedArrowUpFilled  className="text-6xl text-dorado"/>
      </ScrollToTop>
    </div>
  )
}