import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ErrorPage from 'components/error/common';

export const AnimalesNFC = () => {
    const { nfc } = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`https://juskas.onrender.com/api/v1/animales/animales/nfc/${nfc}`);
                navigate(`/registro/${res.data._id}`)
            } catch (error) {
                setError(true)
            }
        }
        fetchData();
    },[nfc , navigate])

    if (error) {
        return   (
          <ErrorPage />
    );
    }

    return null; // Este componente no renderiza nada, ya que realiza una redirección.
};