import { Menu } from "@mui/icons-material";
import {
  AppBar,
  IconButton,
  Stack,
  Toolbar,
} from "@mui/material";
import { useGetIdentity } from "@refinedev/core";
import { RefineThemedLayoutHeaderProps } from "@refinedev/mui";
import React from "react";
import { whitejuskas } from "assets";

type IUser = {
  id: number;
  name: string;
  avatar: string;
};

export const Header: React.FC<RefineThemedLayoutHeaderProps> = ({
  isSiderOpen,
  onToggleSiderClick,
  toggleSiderIcon: toggleSiderIconFromProps,
}) => {

  const { data: user } = useGetIdentity<IUser>();

  const hasSidebarToggle = Boolean(onToggleSiderClick);

  return (
    <AppBar position="sticky">
      <Toolbar>
        <Stack direction="row" width="100%" alignItems="center">
          {hasSidebarToggle && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => onToggleSiderClick?.()}
              edge="start"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                ...(isSiderOpen && { display: "none" }),
              }}
            >
              {toggleSiderIconFromProps?.(Boolean(isSiderOpen)) ?? <Menu />}
            </IconButton>
          )}

          <Stack
            direction="row"
            width="100%"
            justifyContent="flex-end"
            alignItems="center"
            gap="16px"
          >

            {(user?.avatar || user?.name) && (
              <Stack
                direction="row"
                gap="16px"
                alignItems="center"
                justifyContent="center"
              >
                <h1 className="text-sm"><b>Ellos brillan</b> y nos hacen brillar más</h1>
                <img src={whitejuskas} alt='logo-white' className="w-20 p-2"/>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
