import { one, paws, three, two } from "assets";
import { Variants, motion } from "framer-motion"
import { slideDown, slideTop } from "utils/motion";

const Down: Variants = slideDown('down', 'spring', 0, 1)
const Up: Variants = slideTop('up', 'spring', 0, 1)

export const How = () => {
    return (
      <div className="flex flex-col">
        <motion.div 
          className="flex md:justify-between lg:justify-between justify-center"
          variants={Down}
          initial="hidden"
          whileInView="show"
        >
          <img src={paws} alt="paws" className="h-36 -scale-x-100 lg:block md:block hidden"/>
          <div className="text-center">
            <h1 className="font-semibold lg:text-[2.5rem] md:text-[2.5rem] text-[2rem]">¿Cómo funciona?</h1>
            <h2 className="font-light text-[1.2rem] ">Registro Proyecto Juskas</h2>
          </div>
          <img src={paws} alt="paws" className="h-36 lg:block md:block hidden"/>
        </motion.div>
        <motion.div 
          className="flex gap-5 justify-evenly lg:text-left md:text-left text-center flex-wrap"
          variants={Up}
          initial="hidden"
          whileInView="show"
        >
          <div className="flex flex-col lg:items-start md:items-start items-center">
            <img src={one} alt="instructivo uno" className="w-[14rem]"/>
            <h3 className="font-semibold text-xl mb-2">Crea tu Cuenta</h3>
            <p className="font-light max-w-[22rem] text-[1.2rem] ">Regístrate y crea un perfil de tenedor responsable.</p>
          </div>          
          <div className="flex flex-col lg:items-start md:items-start items-center">
            <img src={two} alt="instructivo dos" className="w-[14rem]"/>
            <h3 className="font-semibold text-xl mb-2">Registra tus animales</h3>
            <p className="font-light max-w-[22rem] text-[1.2rem] ">Mediante un sencillo formulario con los datos básicos de tu mascota.</p>
          </div>          
          <div className="flex flex-col lg:items-start md:items-start items-center">
            <img src={three} alt="instructivo tres" className="w-[14rem]"/>
            <h3 className="font-semibold text-xl mb-2">Recoge tu kit</h3>
            <p className="font-light max-w-[22rem] text-[1.2rem] ">Solicita tu kit con microchip con sensor de proximidad y QR y collar.</p>
          </div>
        </motion.div>
        
      </div>
    )
  }