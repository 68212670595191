import { moreliabrilla, tutoriales, animales } from 'assets'

const Tutoriales = () => {
  return (
    <div className='p-4'>
        {/* Titulo */}
        <div className='flex justify-between items-center'>
            <div className='flex gap-3 items-center'>
                <img src={tutoriales} alt='adopta' className='md:h-20 h-14'/>
                <div className='flex flex-col'>
                    <h2 className='font-light'>¿Cómo funciona?</h2>
                    <h1 className='font-bold text-2xl'>Tutoriales</h1>
                </div>
            </div>
            <img src={moreliabrilla} alt='logo morelia' className='h-16 md:h-24'/>
        </div>
        <div className='flex flex-col gap-4 mt-10 items-center justify-center'>
            <img src={animales} alt="animales" className='w-72'/>
            <h1 className='text-2xl text-center font-semibold'>
                Próximamente
            </h1>
        </div>

    </div>
  )
}

export default Tutoriales